"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitMemberPosition = exports.Role = exports.Team = void 0;
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var Styled = __importStar(require("./UserProfile.styled"));
// TODO:(@mango906): 추후 서버에서 내려주는 값에 따라 value값 변경 필요
exports.Team = {
    mashUp: 'MASHUP',
    branding: 'BRANDING',
    design: 'DESIGN',
    android: 'ANDROID',
    ios: 'iOS',
    web: 'WEB',
    node: 'NODE',
    spring: 'SPRING',
};
// TODO:(@mango906): 추후 서버에서 내려주는 값에 따라 value값 변경 필요
exports.Role = {
    leader: 'LEADER',
    subLeader: 'SUBLEADER',
    member: 'MEMBER',
    helper: 'HELPER',
};
var splitMemberPosition = function (position) {
    return position.split('_');
};
exports.splitMemberPosition = splitMemberPosition;
var UserProfile = function (_a) {
    var team = _a.team, role = _a.role, _b = _a.showBackground, showBackground = _b === void 0 ? true : _b, _c = _a.removePadding, removePadding = _c === void 0 ? false : _c;
    return ((0, jsx_runtime_1.jsxs)(Styled.UserProfileContainer, __assign({ showBackground: showBackground, removePadding: removePadding }, { children: [(0, jsx_runtime_1.jsx)("span", { children: team }, void 0), (0, jsx_runtime_1.jsx)(Styled.UserProfileRoleLabel, __assign({ team: team, "$role": role }, { children: role }), void 0)] }), void 0));
};
exports.default = UserProfile;
