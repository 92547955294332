"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fonts = void 0;
var react_1 = require("@emotion/react");
exports.fonts = {
    bold46: (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-weight: 700;\n    font-size: 4.6rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 700;\n    font-size: 4.6rem;\n    line-height: 1.5;\n  "]))),
    bold28: (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-weight: 700;\n    font-size: 2.8rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 700;\n    font-size: 2.8rem;\n    line-height: 1.5;\n  "]))),
    bold24: (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-weight: 700;\n    font-size: 2.4rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 700;\n    font-size: 2.4rem;\n    line-height: 1.5;\n  "]))),
    bold22: (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-weight: 700;\n    font-size: 2.2rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 700;\n    font-size: 2.2rem;\n    line-height: 1.5;\n  "]))),
    regular20: (0, react_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-weight: 400;\n    font-size: 2rem;\n    line-height: 1.6;\n  "], ["\n    font-weight: 400;\n    font-size: 2rem;\n    line-height: 1.6;\n  "]))),
    bold18: (0, react_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-weight: 700;\n    font-size: 1.8rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 700;\n    font-size: 1.8rem;\n    line-height: 1.5;\n  "]))),
    bold17: (0, react_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-weight: 700;\n    font-size: 1.7rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 700;\n    font-size: 1.7rem;\n    line-height: 1.5;\n  "]))),
    bold16: (0, react_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font-weight: 700;\n    font-size: 1.6rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 700;\n    font-size: 1.6rem;\n    line-height: 1.5;\n  "]))),
    medium16: (0, react_1.css)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-weight: 500;\n    font-size: 1.6rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 500;\n    font-size: 1.6rem;\n    line-height: 1.5;\n  "]))),
    regular16: (0, react_1.css)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    font-weight: 400;\n    font-size: 1.6rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 400;\n    font-size: 1.6rem;\n    line-height: 1.5;\n  "]))),
    medium15: (0, react_1.css)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    font-weight: 500;\n    font-size: 1.5rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 500;\n    font-size: 1.5rem;\n    line-height: 1.5;\n  "]))),
    regular15: (0, react_1.css)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    font-weight: 400;\n    font-size: 1.5rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 400;\n    font-size: 1.5rem;\n    line-height: 1.5;\n  "]))),
    medium14: (0, react_1.css)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    font-weight: 500;\n    font-size: 1.4rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 500;\n    font-size: 1.4rem;\n    line-height: 1.5;\n  "]))),
    regular14: (0, react_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    font-weight: 400;\n    font-size: 1.4rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 400;\n    font-size: 1.4rem;\n    line-height: 1.5;\n  "]))),
    medium13: (0, react_1.css)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    font-weight: 500;\n    font-size: 1.3rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 500;\n    font-size: 1.3rem;\n    line-height: 1.5;\n  "]))),
    regular13: (0, react_1.css)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    font-weight: 400;\n    font-size: 1.3rem;\n    line-height: 1.5;\n  "], ["\n    font-weight: 400;\n    font-size: 1.3rem;\n    line-height: 1.5;\n  "]))),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
