"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var react_2 = require("react");
var react_router_dom_1 = require("react-router-dom");
var useBlocker = function (blocker, when) {
    if (when === void 0) { when = true; }
    var navigator = (0, react_2.useContext)(react_router_dom_1.UNSAFE_NavigationContext).navigator;
    (0, react_2.useEffect)(function () {
        if (!when) {
            return;
        }
        var unblock = navigator.block(function (tx) {
            var autoUnblockingTx = __assign(__assign({}, tx), { retry: function () {
                    unblock();
                    tx.retry();
                } });
            blocker(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, blocker, when]);
};
var usePrompt = function (when) {
    if (when === void 0) { when = true; }
    var navigate = (0, react_router_dom_1.useNavigate)();
    var location = (0, react_router_dom_1.useLocation)();
    var _a = __read((0, react_2.useState)(false), 2), showPrompt = _a[0], setShowPrompt = _a[1];
    var _b = __read((0, react_2.useState)(null), 2), lastLocation = _b[0], setLastLocation = _b[1];
    var _c = __read((0, react_2.useState)(false), 2), confirmedNavigation = _c[0], setConfirmedNavigation = _c[1];
    var cancelNavigation = function () {
        setShowPrompt(false);
    };
    var blocker = function (tx) {
        if (!confirmedNavigation && tx.location.pathname !== location.pathname) {
            setShowPrompt(true);
            setLastLocation(tx);
            return false;
        }
        return true;
    };
    var confirmNavigation = function () {
        setShowPrompt(false);
        setConfirmedNavigation(true);
    };
    (0, react_2.useEffect)(function () {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.location.pathname);
        }
    }, [confirmedNavigation, lastLocation, navigate]);
    useBlocker(blocker, when);
    return { showPrompt: showPrompt, confirmNavigation: confirmNavigation, cancelNavigation: cancelNavigation };
};
exports.default = usePrompt;
