"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeType = exports.ScoreTitle = exports.ScoreType = void 0;
exports.ScoreType = {
    ATTENDANCE: 'ATTENDANCE',
    ABSENT: 'ABSENT',
    LATE: 'LATE',
    SEMINAR_PRESENTATION: 'SEMINAR_PRESENTATION',
    DEPLOY_SUCCESS: 'DEPLOY_SUCCESS',
    DEPLOY_FAILURE: 'DEPLOY_FAILURE',
    PROJECT_LEADER: 'PROJECT_LEADER',
    PROJECT_SUBLEADER: 'PROJECT_SUBLEADER',
    HACKATHON_COMMITTEE: 'HACKATHON_COMMITTEE',
    MASHUP_STAFF: 'MASHUP_STAFF',
    MASHUP_LEADER: 'MASHUP_LEADER',
    MASHUP_SUBLEADER: 'MASHUP_SUBLEADER',
    MASHUP_CONTENT_WRITE: 'MASHUP_CONTENT_WRITE',
    ADD_SCORE_DURING_SEMINAR_ACTIVITY_0_5: 'ADD_SCORE_DURING_SEMINAR_ACTIVITY_0_5',
    ADD_SCORE_DURING_SEMINAR_ACTIVITY_1: 'ADD_SCORE_DURING_SEMINAR_ACTIVITY_1',
    DEFAULT: 'DEFAULT',
};
exports.ScoreTitle = (_a = {},
    _a[exports.ScoreType.ATTENDANCE] = '전체 세미나 출석',
    _a[exports.ScoreType.ABSENT] = '전체 세미나 결석',
    _a[exports.ScoreType.LATE] = '전체 세미나 지각',
    _a[exports.ScoreType.SEMINAR_PRESENTATION] = '전체 세미나 발표',
    _a[exports.ScoreType.DEPLOY_SUCCESS] = '프로젝트 배포 성공',
    _a[exports.ScoreType.DEPLOY_FAILURE] = '프로젝트 배포 실패',
    _a[exports.ScoreType.PROJECT_LEADER] = '프로젝트 팀장',
    _a[exports.ScoreType.PROJECT_SUBLEADER] = '프로젝트 부팀장',
    _a[exports.ScoreType.HACKATHON_COMMITTEE] = '준비 위원회',
    _a[exports.ScoreType.MASHUP_STAFF] = '스태프',
    _a[exports.ScoreType.MASHUP_LEADER] = '회장',
    _a[exports.ScoreType.MASHUP_SUBLEADER] = '부회장',
    _a[exports.ScoreType.MASHUP_CONTENT_WRITE] = '블로그 콘텐츠 작성',
    _a);
exports.RangeType = {
    Plus: 'Plus',
    Minus: 'Minus',
    Normal: 'Normal',
};
