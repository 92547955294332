"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var Styled = __importStar(require("./ScheduleInfoList.styled"));
var utils_1 = require("@/utils");
var ScheduleInfoList = function (_a) {
    var name = _a.name, generationNumber = _a.generationNumber, scheduleType = _a.scheduleType, startedAt = _a.startedAt, createdAt = _a.createdAt, publishedAt = _a.publishedAt, status = _a.status, location = _a.location, notice = _a.notice;
    var scheduleInfoListItem = (0, react_2.useMemo)(function () {
        return [
            {
                label: '스케줄 명',
                value: name,
            },
            {
                label: '기수',
                value: "".concat(generationNumber, "\uAE30"),
            },
            {
                label: '구분',
                value: (0, utils_1.getScheduleType)(scheduleType),
            },
            {
                label: '스케줄 일시',
                value: (0, utils_1.formatDate)(startedAt, 'YYYY년 M월 D일 A hh시 mm분'),
            },
            {
                label: '등록 일시',
                value: (0, utils_1.formatDate)(createdAt, 'YYYY년 M월 D일 A hh시 mm분'),
            },
            {
                label: '장소',
                value: location.roadAddress === null
                    ? location.detailAddress // ZOOM
                    : "".concat(location.roadAddress).concat(location.detailAddress ? ", ".concat(location.detailAddress) : ''),
            },
            {
                label: '공지',
                value: notice || '-',
            },
            {
                label: '배포 일시',
                value: (0, utils_1.formatDate)(publishedAt, 'YYYY년 M월 D일 A hh시 mm분'),
            },
            {
                label: '배포 상태',
                value: (0, utils_1.getScheduleStatusText)(status),
            },
        ];
    }, [
        createdAt,
        generationNumber,
        location.detailAddress,
        location.roadAddress,
        name,
        notice,
        publishedAt,
        scheduleType,
        startedAt,
        status,
    ]);
    return ((0, jsx_runtime_1.jsx)(Styled.ScheduleInfoList, { children: scheduleInfoListItem.map(function (_a) {
            var label = _a.label, value = _a.value;
            return ((0, jsx_runtime_1.jsxs)("li", { children: [(0, jsx_runtime_1.jsx)(Styled.ScheduleInfoLabel, { children: label }, void 0), (0, jsx_runtime_1.jsx)(Styled.ScheduleInfoValue, { children: value }, void 0)] }, label));
        }) }, void 0));
};
exports.default = ScheduleInfoList;
