"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.a11y = void 0;
var react_1 = require("@emotion/react");
exports.a11y = {
    visuallyHidden: (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: absolute;\n    display: inline-block;\n    width: 0.1rem;\n    height: 0.1rem;\n    margin: -0.1rem;\n    padding: 0;\n    overflow: hidden;\n    white-space: nowrap;\n    border: 0;\n    clip: rect(0 0 0 0);\n    clip-path: polygon(0 0, 0 0, 0 0);\n  "], ["\n    position: absolute;\n    display: inline-block;\n    width: 0.1rem;\n    height: 0.1rem;\n    margin: -0.1rem;\n    padding: 0;\n    overflow: hidden;\n    white-space: nowrap;\n    border: 0;\n    clip: rect(0 0 0 0);\n    clip-path: polygon(0 0, 0 0, 0 0);\n  "]))),
};
var templateObject_1;
