"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultEditorData = void 0;
var getDefaultEditorData = function () {
    return {
        time: new Date().getTime(),
        blocks: [{ type: 'paragraph', data: {} }],
    };
};
exports.getDefaultEditorData = getDefaultEditorData;
