"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var react_router_dom_1 = require("react-router-dom");
var store_1 = require("@/store");
var components_1 = require("@/components");
var Modal = function (_a) {
    var modalKey = _a.modalKey;
    var modal = (0, recoil_1.useRecoilValue)((0, store_1.$modalByStorage)(modalKey));
    if (modalKey === store_1.ModalKey.alertModalDialog && modal.isOpen && modal.props) {
        return (0, jsx_runtime_1.jsx)(components_1.AlertModalDialog, __assign({}, modal.props), modalKey);
    }
    if (modalKey === store_1.ModalKey.changeResultModalDialog && modal.isOpen && modal.props) {
        return ((0, jsx_runtime_1.jsx)(components_1.ChangeResultModalDialog, __assign({}, modal.props), modalKey));
    }
    if (modalKey === store_1.ModalKey.emailSendModalDialog && modal.isOpen && modal.props) {
        return (0, jsx_runtime_1.jsx)(components_1.EmailSendModalDialog, __assign({}, modal.props), modalKey);
    }
    if (modalKey === store_1.ModalKey.emailSendDetailListModalDialog && modal.isOpen && modal.props) {
        return ((0, jsx_runtime_1.jsx)(components_1.EmailSendDetailListModalDialog, __assign({}, modal.props), modalKey));
    }
    if (modalKey === store_1.ModalKey.emailSendDetailInfoModalDialog && modal.isOpen && modal.props) {
        return ((0, jsx_runtime_1.jsx)(components_1.EmailSendDetailInfoModalDialog, __assign({}, modal.props), modalKey));
    }
    if (modalKey === store_1.ModalKey.applyActivityScoreModalDialog && modal.isOpen && modal.props) {
        return ((0, jsx_runtime_1.jsx)(components_1.ApplyActivityScoreModalDialog, __assign({}, modal.props), modalKey));
    }
    if (modalKey === store_1.ModalKey.activityScoreModalDialog && modal.isOpen && modal.props) {
        return ((0, jsx_runtime_1.jsx)(components_1.ActivityScoreModalDialog, __assign({}, modal.props), modalKey));
    }
    if (modalKey === store_1.ModalKey.createQRCodeModalDialog && modal.isOpen && modal.props) {
        return ((0, jsx_runtime_1.jsx)(components_1.CreateQRCodeModalDialog, __assign({}, modal.props), modalKey));
    }
    if (modalKey === store_1.ModalKey.displayQRCodeModalDialog && modal.isOpen && modal.props) {
        return ((0, jsx_runtime_1.jsx)(components_1.DisplayQRCodeModalDialog, __assign({}, modal.props), modalKey));
    }
    if (modalKey === store_1.ModalKey.createHelperAdminMemberDialog && modal.isOpen && modal.props) {
        return ((0, jsx_runtime_1.jsx)(components_1.CreateHelperAdminMemberDialog, __assign({}, modal.props), modalKey));
    }
    return null;
};
var ModalViewer = function () {
    var setAlertModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog));
    var setChangeResultModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.changeResultModalDialog));
    var setEmailSendModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.emailSendModalDialog));
    var setEmailSendDetailListModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.emailSendDetailListModalDialog));
    var setEmailSendDetailInfoModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.emailSendDetailInfoModalDialog));
    var setCreateHelperAdminMemberDialog = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.createHelperAdminMemberDialog));
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    // TODO:(용재) 추후 더 나은 방법 찾아보기..
    (0, react_2.useEffect)(function () {
        setAlertModal({ key: store_1.ModalKey.alertModalDialog, isOpen: false });
        setChangeResultModal({ key: store_1.ModalKey.changeResultModalDialog, isOpen: false });
        if (!/\/application\/\d/g.test(pathname)) {
            setEmailSendModal({ key: store_1.ModalKey.emailSendModalDialog, isOpen: false });
        }
        setEmailSendDetailListModal({
            key: store_1.ModalKey.emailSendDetailListModalDialog,
            isOpen: false,
        });
        setEmailSendDetailInfoModal({
            key: store_1.ModalKey.emailSendDetailInfoModalDialog,
            isOpen: false,
        });
        setCreateHelperAdminMemberDialog({
            key: store_1.ModalKey.createHelperAdminMemberDialog,
            isOpen: false,
        });
    }, [
        setAlertModal,
        setChangeResultModal,
        setEmailSendModal,
        pathname,
        setEmailSendDetailListModal,
        setEmailSendDetailInfoModal,
        setCreateHelperAdminMemberDialog,
    ]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: Object.values(store_1.ModalKey).map(function (each) {
            return (0, jsx_runtime_1.jsx)(Modal, { modalKey: each }, each);
        }) }, void 0));
};
exports.default = ModalViewer;
