"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var react_2 = require("react");
var useOnClickOutside = function (ref, handler) {
    (0, react_2.useEffect)(function () {
        var listener = function (event) {
            if (!ref.current || ref.current.contains(event === null || event === void 0 ? void 0 : event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, handler]);
};
exports.default = useOnClickOutside;
