"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var react_2 = require("react");
var useUnmount = function (callback) {
    (0, react_2.useEffect)(function () {
        return function () { return callback(); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
exports.default = useUnmount;
