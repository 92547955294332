"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$teamIdByName = exports.$teams = void 0;
var recoil_1 = require("recoil");
exports.$teams = (0, recoil_1.atom)({
    key: 'teams',
    default: [],
});
exports.$teamIdByName = (0, recoil_1.selectorFamily)({
    key: 'teamIdByName',
    get: function (teamName) {
        return function (_a) {
            var get = _a.get;
            var teams = get(exports.$teams);
            return teams.reduce(function (prevId, team) {
                return team.name.toLocaleLowerCase() === (teamName === null || teamName === void 0 ? void 0 : teamName.toLocaleLowerCase())
                    ? team.teamId.toString()
                    : prevId;
            }, '');
        };
    },
});
