"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var spoqaHanSansNeo = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @font-face {\n    font-weight: 400;\n    font-family: 'SpoqaHanSansNeo';\n    font-style: normal;\n    src: local('Spoqa Han Sans Neo Regular'), local('SpoqaHanSansNeo-Regular'),\n      url('fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2');\n    font-display: swap;\n  }\n\n  @font-face {\n    font-weight: 500;\n    font-family: 'SpoqaHanSansNeo';\n    font-style: normal;\n    src: local('Spoqa Han Sans Neo Medium'), local('SpoqaHanSansNeo-Medium'),\n      url('fonts/SpoqaHanSansNeo-Medium.woff2') format('woff2');\n    font-display: swap;\n  }\n\n  @font-face {\n    font-weight: 700;\n    font-family: 'SpoqaHanSansNeo';\n    font-style: normal;\n    src: local('Spoqa Han Sans Neo Bold'), local('SpoqaHanSansNeo-Bold'),\n      url('fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2');\n    font-display: swap;\n  }\n"], ["\n  @font-face {\n    font-weight: 400;\n    font-family: 'SpoqaHanSansNeo';\n    font-style: normal;\n    src: local('Spoqa Han Sans Neo Regular'), local('SpoqaHanSansNeo-Regular'),\n      url('fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2');\n    font-display: swap;\n  }\n\n  @font-face {\n    font-weight: 500;\n    font-family: 'SpoqaHanSansNeo';\n    font-style: normal;\n    src: local('Spoqa Han Sans Neo Medium'), local('SpoqaHanSansNeo-Medium'),\n      url('fonts/SpoqaHanSansNeo-Medium.woff2') format('woff2');\n    font-display: swap;\n  }\n\n  @font-face {\n    font-weight: 700;\n    font-family: 'SpoqaHanSansNeo';\n    font-style: normal;\n    src: local('Spoqa Han Sans Neo Bold'), local('SpoqaHanSansNeo-Bold'),\n      url('fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2');\n    font-display: swap;\n  }\n"])));
exports.default = spoqaHanSansNeo;
var templateObject_1;
