"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var react_hook_form_1 = require("react-hook-form");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var Styled = __importStar(require("./ApplyActivityScoreModalDialog.styled"));
var constants_1 = require("../../ActivityScore/constants");
var utils_1 = require("../../ActivityScore/utils");
var Input_component_1 = require("@/components/common/Input/Input.component");
var utils_2 = require("@/utils");
var api = __importStar(require("@/api"));
var hooks_1 = require("@/hooks");
var store_1 = require("@/store");
var member_1 = require("@/store/member");
var styles_1 = require("@/styles");
var scoreTypes = [
    {
        label: '활동',
        items: [
            {
                value: constants_1.ScoreType.SEMINAR_PRESENTATION,
                label: '전체 세미나 발표',
                rangeType: constants_1.RangeType.Plus,
                range: 1,
            },
            {
                value: constants_1.ScoreType.ADD_SCORE_DURING_SEMINAR_ACTIVITY_0_5,
                label: '세미나 활동중 추가 점수(0.5)',
                rangeType: constants_1.RangeType.Plus,
                range: 0.5,
            },
            {
                value: constants_1.ScoreType.ADD_SCORE_DURING_SEMINAR_ACTIVITY_1,
                label: '세미나 활동중 추가 점수(1)',
                rangeType: constants_1.RangeType.Plus,
                range: 1,
            },
            {
                value: constants_1.ScoreType.HACKATHON_COMMITTEE,
                label: '준비위원회',
                rangeType: constants_1.RangeType.Plus,
                range: 1,
            },
            {
                value: constants_1.ScoreType.DEPLOY_FAILURE,
                label: '프로젝트 MVP 배포 실패',
                rangeType: constants_1.RangeType.Minus,
                range: 0.5,
            },
        ],
    },
    {
        label: '출결',
        items: [
            {
                value: constants_1.ScoreType.ATTENDANCE,
                label: '출석',
                rangeType: constants_1.RangeType.Plus,
                range: 1,
            },
            {
                value: constants_1.ScoreType.LATE,
                label: '지각',
                rangeType: constants_1.RangeType.Plus,
                range: 0.5,
            },
        ],
    },
    {
        label: '직위',
        items: [
            { value: constants_1.ScoreType.MASHUP_STAFF, label: '운영진', rangeType: constants_1.RangeType.Plus, range: 2 },
            {
                value: constants_1.ScoreType.PROJECT_LEADER,
                label: '프로젝트 팀 리더',
                rangeType: constants_1.RangeType.Plus,
                range: 1,
            },
        ],
    },
];
var ApplyActivityScoreModalDialog = function (_a) {
    var generationNumber = _a.generationNumber, memberId = _a.memberId;
    var handleAddToast = (0, hooks_1.useToast)().handleAddToast;
    var _b = __read((0, react_2.useState)(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var handleApplyActivityScoreModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.applyActivityScoreModalDialog));
    var methods = (0, react_hook_form_1.useForm)({ mode: 'onChange' });
    var handleSubmit = methods.handleSubmit, register = methods.register, control = methods.control, formState = methods.formState;
    var isSubmittable = formState.dirtyFields.date && formState.dirtyFields.scoreType;
    var handleCloseModal = function () {
        return handleApplyActivityScoreModal({ key: store_1.ModalKey.applyActivityScoreModalDialog, isOpen: false });
    };
    var handleAddScore = (0, recoil_1.useRecoilCallback)(function (_a) {
        var refresh = _a.refresh;
        return function (_a) {
            var date = _a.date, memo = _a.memo, scoreType = _a.scoreType;
            return __awaiter(void 0, void 0, void 0, function () {
                var formattedDate;
                return __generator(this, function (_b) {
                    formattedDate = (0, utils_2.formatDate)(date, 'YYYY-MM-DD');
                    (0, utils_2.request)({
                        requestFunc: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        setIsLoading(true);
                                        return [4 /*yield*/, api.postScoreHistoryAdd({
                                                date: formattedDate,
                                                memo: memo,
                                                scoreType: scoreType,
                                                generationNumber: generationNumber,
                                                memberId: memberId,
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); },
                        errorHandler: handleAddToast,
                        onSuccess: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                handleCloseModal();
                                handleAddToast({
                                    type: styles_1.ToastType.success,
                                    message: '활동점수 히스토리가 추가되었습니다.',
                                });
                                refresh((0, member_1.$memberDetail)({
                                    generationNumber: generationNumber,
                                    memberId: memberId,
                                }));
                                return [2 /*return*/];
                            });
                        }); },
                        onCompleted: function () {
                            setIsLoading(false);
                        },
                    });
                    return [2 /*return*/];
                });
            });
        };
    });
    return ((0, jsx_runtime_1.jsx)(Styled.ApplyActivityScoreModalWrapper, __assign({ heading: "\uD65C\uB3D9\uC810\uC218 \uC801\uC6A9", handleCloseModal: handleCloseModal, footer: {
            confirmButton: {
                label: '적용',
                onClick: handleSubmit(handleAddScore),
                isLoading: isLoading,
                disabled: !isSubmittable,
            },
            cancelButton: { label: '취소', onClick: handleCloseModal },
        } }, { children: (0, jsx_runtime_1.jsxs)(Styled.ModalInner, { children: [(0, jsx_runtime_1.jsxs)(Styled.ScoreSection, { children: [(0, jsx_runtime_1.jsxs)(Styled.ScoreSectionLabel, { children: ["\uD65C\uB3D9\uC810\uC218 \uB9AC\uC2A4\uD2B8", (0, jsx_runtime_1.jsx)(Styled.RequiredDot, {}, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(Styled.ScoreTypeList, { children: scoreTypes.map(function (scoreType) {
                                return ((0, jsx_runtime_1.jsxs)("li", { children: [(0, jsx_runtime_1.jsx)(Styled.Label, { children: scoreType.label }, void 0), (0, jsx_runtime_1.jsx)(Styled.RadioButtonGroup, { children: scoreType.items.map(function (scoreTypeItem) { return ((0, jsx_runtime_1.jsxs)(Styled.RadioButtonGroupItem, { children: [(0, jsx_runtime_1.jsx)(components_1.RadioButtonField, __assign({}, register('scoreType', { required: true }), { label: scoreTypeItem.label, value: scoreTypeItem.value }), void 0), (0, jsx_runtime_1.jsx)(Styled.ScoreText, __assign({ rangeType: scoreTypeItem.rangeType }, { children: (0, utils_1.getRangeText)(scoreTypeItem.range, scoreTypeItem.rangeType) }), void 0)] }, scoreTypeItem.label)); }) }, void 0)] }, scoreType.label));
                            }) }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(Styled.Divider, {}, void 0), (0, jsx_runtime_1.jsxs)(Styled.InputSection, { children: [(0, jsx_runtime_1.jsx)(Styled.StyledDatePickerField, __assign({ control: control, "$size": Input_component_1.InputSize.md, label: "\uB0A0\uC9DC", placeholder: "\uB0B4\uC6A9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694", required: true }, register('date', { required: true })), void 0), (0, jsx_runtime_1.jsx)(components_1.InputField, __assign({}, register('memo'), { fill: true, label: "\uBA54\uBAA8", "$size": Input_component_1.InputSize.md, placeholder: "\uB0B4\uC6A9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694" }), void 0)] }, void 0)] }, void 0) }), void 0));
};
exports.default = ApplyActivityScoreModalDialog;
