"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var react_router_dom_1 = require("react-router-dom");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var Styled = __importStar(require("./ActivityScoreList.styled"));
var constants_1 = require("@/constants");
var hooks_1 = require("@/hooks");
var store_1 = require("@/store");
var member_1 = require("@/store/member");
var ActivityScoreList = function () {
    var _a, _b;
    var _c = __read((0, react_router_dom_1.useSearchParams)(), 1), searchParams = _c[0];
    var generationNumber = (0, recoil_1.useRecoilValue)(store_1.$generationNumber);
    var page = searchParams.get('page') || '1';
    var size = searchParams.get('size') || '20';
    var team = searchParams.get('team') || '';
    var searchWord = searchParams.get('searchWord') || '';
    var _d = __read((0, react_2.useState)([
        { accessor: 'name', type: constants_1.SORT_TYPE.DEFAULT },
        { accessor: 'score', type: constants_1.SORT_TYPE.DEFAULT },
    ]), 2), sortTypes = _d[0], setSortTypes = _d[1];
    var sortParam = (0, react_2.useMemo)(function () {
        var matched = sortTypes.find(function (sortType) { return sortType.type !== constants_1.SORT_TYPE.DEFAULT; });
        if (!matched)
            return '';
        var accessor = matched.accessor, type = matched.type;
        return "".concat(accessor, ",").concat(type);
    }, [sortTypes]);
    var _e = (0, react_router_dom_1.useLocation)(), pathname = _e.pathname, search = _e.search;
    var membersParams = (0, react_2.useMemo)(function () { return ({
        page: parseInt(page, 10) - 1,
        size: parseInt(size, 10),
        generationNumber: generationNumber,
        sort: sortParam,
        platform: team,
        searchName: searchWord,
    }); }, [generationNumber, page, size, sortParam, team, searchWord]);
    var _f = __read((0, recoil_1.useRecoilStateLoadable)((0, member_1.$members)(membersParams)), 1), contents = _f[0].contents;
    var tableRows = (_a = contents.data) !== null && _a !== void 0 ? _a : [];
    var _g = ((_b = contents.page) !== null && _b !== void 0 ? _b : {}).totalCount, totalCount = _g === void 0 ? 0 : _g;
    var _h = (0, hooks_1.usePagination)({
        totalCount: totalCount,
        pagingSize: 20,
    }), pageOptions = _h.pageOptions, handleChangePage = _h.handleChangePage, handleChangeSize = _h.handleChangeSize;
    var columns = [
        {
            title: '이름',
            widthRatio: '25%',
            accessor: 'name',
            idAccessor: 'memberId',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue, id = _a.id, handleClickLink = _a.handleClickLink;
                return ((0, jsx_runtime_1.jsxs)(Styled.FormTitleWrapper, __assign({ title: cellValue }, { children: [(0, jsx_runtime_1.jsx)(Styled.FormTitle, { children: cellValue }, void 0), handleClickLink ? ((0, jsx_runtime_1.jsx)(Styled.TitleButton, { type: "button", onClick: handleClickLink }, void 0)) : ((0, jsx_runtime_1.jsx)(Styled.TitleLink, { to: "".concat(constants_1.PATH.ACTIVITY_SCORE, "/").concat(generationNumber, "/").concat(id), state: { from: "".concat(pathname).concat(search) } }, void 0))] }), void 0));
            },
        },
        {
            title: '아이디',
            widthRatio: '25%',
            accessor: 'identification',
        },
        {
            title: '플랫폼',
            widthRatio: '25%',
            accessor: 'platform',
        },
        {
            title: '활동점수',
            widthRatio: '25%',
            accessor: 'score',
        },
    ];
    return ((0, jsx_runtime_1.jsxs)(Styled.PageWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.Heading, { children: "\uD65C\uB3D9\uC810\uC218" }, void 0), (0, jsx_runtime_1.jsxs)(Styled.StickyContainer, { children: [(0, jsx_runtime_1.jsx)(components_1.TeamNavigationTabs, {}, void 0), (0, jsx_runtime_1.jsx)(components_1.SearchOptionBar, { placeholder: "\uC774\uB984 \uAC80\uC0C9" }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(components_1.Table, { prefix: "activity-score", topStickyHeight: 14.1, columns: columns, rows: tableRows, supportBar: { totalSummaryText: '총 인원', totalCount: totalCount }, sortOptions: {
                    sortTypes: sortTypes,
                    disableMultiSort: true,
                    handleSortColumn: function (_sortTypes) {
                        setSortTypes(_sortTypes);
                    },
                }, pagination: (0, jsx_runtime_1.jsx)(components_1.Pagination, { pageOptions: pageOptions, selectableSize: {
                        selectBoxPosition: totalCount > 3 ? 'top' : 'bottom',
                        handleChangeSize: handleChangeSize,
                    }, handleChangePage: handleChangePage }, void 0) }, void 0), (0, jsx_runtime_1.jsx)(components_1.BottomCTA, __assign({ boundaries: {
                    visibility: { topHeight: 179, bottomHeight: 20 },
                    noAnimation: { bottomHeight: 20 },
                } }, { children: (0, jsx_runtime_1.jsx)(components_1.Pagination, { pageOptions: pageOptions, selectableSize: {
                        selectBoxPosition: totalCount > 3 ? 'top' : 'bottom',
                        handleChangeSize: handleChangeSize,
                    }, handleChangePage: handleChangePage }, void 0) }), void 0)] }, void 0));
};
exports.default = ActivityScoreList;
