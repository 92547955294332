"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var Styled = __importStar(require("./UpdatePlatformRecruit.styled"));
var store_1 = require("@/store");
var Select_component_1 = require("@/components/common/Select/Select.component");
var utils_1 = require("@/utils");
var hooks_1 = require("@/hooks");
var Toast_component_1 = require("@/components/common/Toast/Toast.component");
var api = __importStar(require("@/api"));
var UserProfile_component_1 = require("@/components/common/UserProfile/UserProfile.component");
var EDITOR_ID = 'platform-recruit-editor';
var UpdatePlatformRecruit = function () {
    var _a, _b;
    var _c = __read((0, react_2.useState)(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = __read((0, react_2.useState)((0, utils_1.getDefaultEditorData)()), 2), savedEditorData = _d[0], setSavedEditorData = _d[1];
    var _e = __read((0, react_2.useState)(''), 2), selectedPlatform = _e[0], setSelectedPlatform = _e[1];
    var storageKey = "recruit-".concat(selectedPlatform);
    var handleAddToast = (0, hooks_1.useToast)().handleAddToast;
    var teams = (0, recoil_1.useRecoilValue)(store_1.$teams);
    var myTeamName = (0, recoil_1.useRecoilValue)(store_1.$profile)[0];
    var isStaffMember = myTeamName === UserProfile_component_1.Team.mashUp || myTeamName === UserProfile_component_1.Team.branding;
    var myTeamInfo = isStaffMember
        ? null
        : teams.find(function (_a) {
            var name = _a.name;
            return name.toUpperCase() === myTeamName.toUpperCase();
        });
    var myTeamOption = {
        value: ((_a = myTeamInfo === null || myTeamInfo === void 0 ? void 0 : myTeamInfo.teamId) !== null && _a !== void 0 ? _a : '').toString(),
        label: (_b = myTeamInfo === null || myTeamInfo === void 0 ? void 0 : myTeamInfo.name) !== null && _b !== void 0 ? _b : '',
    };
    var allTeamOptions = teams.map(function (_a) {
        var teamId = _a.teamId, name = _a.name;
        return ({
            value: teamId.toString(),
            label: name,
        });
    });
    var handleUpdateButtonClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var originalOutputData, storageValue;
        var _a;
        return __generator(this, function (_b) {
            originalOutputData = JSON.parse((_a = localStorage.getItem(EDITOR_ID)) !== null && _a !== void 0 ? _a : '{}');
            storageValue = { editorData: originalOutputData };
            (0, utils_1.request)({
                requestFunc: function () {
                    setIsLoading(true);
                    return api.postStorage({ keyString: storageKey, valueMap: storageValue });
                },
                errorHandler: function () {
                    handleAddToast({
                        type: Toast_component_1.ToastType.error,
                        message: '모집공고를 저장하지 못했습니다. 다시 시도해주세요.',
                    });
                },
                onSuccess: function () {
                    handleAddToast({
                        type: Toast_component_1.ToastType.success,
                        message: '성공적으로 모집공고를 저장했습니다.',
                    });
                },
                onCompleted: function () {
                    setIsLoading(false);
                },
            });
            return [2 /*return*/];
        });
    }); };
    var getPlatformRecruit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, editorOutputData, modifiedData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.getStorage(storageKey)];
                case 1:
                    data = (_a.sent()).data;
                    editorOutputData = data.valueMap.editorData;
                    modifiedData = JSON.parse((0, utils_1.decodeHTMLEntities)((0, utils_1.removeWrongAmpString)(JSON.stringify(editorOutputData))));
                    return [2 /*return*/, modifiedData];
            }
        });
    }); };
    (0, react_2.useEffect)(function () {
        if (myTeamInfo === null || myTeamInfo === void 0 ? void 0 : myTeamInfo.name) {
            setSelectedPlatform(myTeamInfo === null || myTeamInfo === void 0 ? void 0 : myTeamInfo.name.toLowerCase());
        }
        else if (isStaffMember) {
            setSelectedPlatform('design');
        }
    }, [myTeamInfo, isStaffMember]);
    (0, react_2.useEffect)(function () {
        var setPlatformRecruit = function () { return __awaiter(void 0, void 0, void 0, function () {
            var newPlatformRecruit, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getPlatformRecruit()];
                    case 1:
                        newPlatformRecruit = _a.sent();
                        setSavedEditorData(newPlatformRecruit);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        handleAddToast({
                            type: Toast_component_1.ToastType.error,
                            message: '모집공고를 불러오지 못했습니다. 다시 시도해주세요.',
                        });
                        return [2 /*return*/, (0, utils_1.getDefaultEditorData)()];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (selectedPlatform) {
            setPlatformRecruit();
        }
    }, [selectedPlatform]);
    return ((0, jsx_runtime_1.jsxs)(Styled.PageWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.Heading, { children: "\uBAA8\uC9D1\uACF5\uACE0" }, void 0), (0, jsx_runtime_1.jsxs)(Styled.EditorWrapper, { children: [(0, jsx_runtime_1.jsx)(components_1.Editor, { id: EDITOR_ID, savedData: savedEditorData }, void 0), (0, jsx_runtime_1.jsx)(components_1.EditorAside, { platform: (0, jsx_runtime_1.jsx)(Styled.TeamSelect, { placeholder: "\uD50C\uB7AB\uD3FC \uC120\uD0DD", defaultValue: isStaffMember ? allTeamOptions[0] : myTeamOption, size: Select_component_1.SelectSize.sm, options: isStaffMember ? allTeamOptions : [myTeamOption], onChangeOption: function (option) { return setSelectedPlatform(option.label.toLowerCase()); } }, void 0), rightActionButton: {
                            text: '수정',
                            onClick: handleUpdateButtonClick,
                            isLoading: isLoading,
                        } }, void 0)] }, void 0)] }, void 0));
};
exports.default = UpdatePlatformRecruit;
