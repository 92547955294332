"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var react_2 = require("react");
var xlsx_1 = require("xlsx");
var useConvertToXlsx = function (_a) {
    var workSheet = _a.workSheet, teamName = _a.teamName, isLoading = _a.isLoading;
    var workBookRef = (0, react_2.useRef)();
    (0, react_2.useEffect)(function () {
        if (workSheet) {
            workBookRef.current = xlsx_1.utils.book_new();
            var sheet = xlsx_1.utils.json_to_sheet(workSheet);
            xlsx_1.utils.book_append_sheet(workBookRef.current, sheet, teamName);
        }
    }, [teamName, workSheet, isLoading]);
    return { getWorkBook: function () { return workBookRef.current; } };
};
exports.default = useConvertToXlsx;
