"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isArray = exports.isObject = void 0;
var isObject = function (obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
};
exports.isObject = isObject;
var isArray = function (array) {
    return Object.prototype.toString.call(array) === '[object Array]';
};
exports.isArray = isArray;
