"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScoreTextColor = exports.getScoreRangeType = exports.getRangeText = void 0;
var react_1 = require("@emotion/react");
var constants_1 = require("./constants");
var getRangeText = function (range, rangeType) {
    if (rangeType === constants_1.RangeType.Plus) {
        return "+".concat(range);
    }
    if (rangeType === constants_1.RangeType.Minus) {
        return "-".concat(range);
    }
    return range;
};
exports.getRangeText = getRangeText;
var getScoreRangeType = function (score) {
    if (score < 0) {
        return constants_1.RangeType.Minus;
    }
    if (score > 0) {
        return constants_1.RangeType.Plus;
    }
    return constants_1.RangeType.Normal;
};
exports.getScoreRangeType = getScoreRangeType;
var getScoreTextColor = function (type, theme) {
    var textColor = '';
    if (type === constants_1.RangeType.Normal) {
        textColor = theme.colors.gray80;
    }
    if (type === constants_1.RangeType.Minus) {
        textColor = theme.colors.red70;
    }
    if (type === constants_1.RangeType.Plus) {
        textColor = theme.colors.blue70;
    }
    return (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), textColor);
};
exports.getScoreTextColor = getScoreTextColor;
var templateObject_1;
