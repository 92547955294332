"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var recoil_1 = require("recoil");
var Styled = __importStar(require("./SessionListItem.styled"));
var __1 = require("..");
var components_1 = require("@/components");
var Button_component_1 = require("@/components/common/Button/Button.component");
var store_1 = require("@/store");
var SessionListItem = function (_a) {
    var scheduleId = _a.scheduleId, eventId = _a.eventId, eventName = _a.eventName, startedAt = _a.startedAt, endedAt = _a.endedAt, contentList = _a.contentList;
    var handleQRCodeModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.createQRCodeModalDialog));
    return ((0, jsx_runtime_1.jsxs)(Styled.SessionListItem, { children: [(0, jsx_runtime_1.jsxs)(Styled.Header, { children: [(0, jsx_runtime_1.jsx)(Styled.SessionTitle, { children: eventName }, void 0), (0, jsx_runtime_1.jsx)(Styled.ButtonWrapper, { children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", "$size": Button_component_1.ButtonSize.xs, shape: Button_component_1.ButtonShape.primary, label: "QR \uB9CC\uB4E4\uAE30", onClick: function () {
                                return handleQRCodeModal({
                                    key: store_1.ModalKey.createQRCodeModalDialog,
                                    isOpen: true,
                                    props: {
                                        scheduleId: scheduleId,
                                        eventId: eventId,
                                        sessionStartedAt: startedAt,
                                        sessionEndedAt: endedAt,
                                    },
                                });
                            } }, void 0) }, void 0), (0, jsx_runtime_1.jsx)(__1.SessionTimeRange, { startedAt: startedAt, endedAt: endedAt }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(Styled.ContentList, { children: contentList.map(function (content, index) { return ((0, jsx_runtime_1.jsx)(__1.ContentListItem, __assign({ index: index }, content), content.contentId)); }) }, void 0)] }, void 0));
};
exports.default = SessionListItem;
