"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmsStatus = exports.ApplicantStatus = exports.ApplicationResultStatusInDto = exports.ApplicationConfirmationStatusInDto = void 0;
exports.ApplicationConfirmationStatusInDto = {
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    INTERVIEW_CONFIRM_WAITING: 'INTERVIEW_CONFIRM_WAITING',
    INTERVIEW_CONFIRM_ACCEPTED: 'INTERVIEW_CONFIRM_ACCEPTED',
    INTERVIEW_CONFIRM_REJECTED: 'INTERVIEW_CONFIRM_REJECTED',
    FINAL_CONFIRM_WAITING: 'FINAL_CONFIRM_WAITING',
    FINAL_CONFIRM_ACCEPTED: 'FINAL_CONFIRM_ACCEPTED',
    FINAL_CONFIRM_REJECTED: 'FINAL_CONFIRM_REJECTED',
    TO_BE_DETERMINED: 'TO_BE_DETERMINED',
};
exports.ApplicationResultStatusInDto = {
    NOT_RATED: 'NOT_RATED',
    SCREENING_TO_BE_DETERMINED: 'SCREENING_TO_BE_DETERMINED',
    SCREENING_FAILED: 'SCREENING_FAILED',
    SCREENING_PASSED: 'SCREENING_PASSED',
    INTERVIEW_TO_BE_DETERMINED: 'INTERVIEW_TO_BE_DETERMINED',
    INTERVIEW_FAILED: 'INTERVIEW_FAILED',
    INTERVIEW_PASSED: 'INTERVIEW_PASSED',
};
exports.ApplicantStatus = {
    active: 'ACTIVE',
    withdrawal: 'WITHDRAWAL',
};
exports.SmsStatus = {
    CREATED: '메시지 생성',
    FAILURE: '발송실패',
    SUCCESS: '발송성공',
};
