"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var react_router_dom_1 = require("react-router-dom");
var react_hook_form_1 = require("react-hook-form");
var components_1 = require("@/components");
var Styled = __importStar(require("./ApplicationFormDetail.styled"));
var types_1 = require("@/types");
var applicationForm_1 = require("@/store/applicationForm");
var Input_component_1 = require("@/components/common/Input/Input.component");
var api = __importStar(require("@/api"));
var hooks_1 = require("@/hooks");
var utils_1 = require("@/utils");
var constants_1 = require("@/constants");
var store_1 = require("@/store");
var Toast_component_1 = require("@/components/common/Toast/Toast.component");
var ApplicationFormDetail = function () {
    var _a = __read((0, react_2.useState)(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var id = (0, react_router_dom_1.useParams)().id;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var handleAddToast = (0, hooks_1.useToast)().handleAddToast;
    var refreshSelectorFamilyByKey = (0, hooks_1.useRefreshSelectorFamilyByKey)();
    var _b = __read((0, recoil_1.useRecoilState)((0, applicationForm_1.$applicationFormDetail)({ id: id !== null && id !== void 0 ? id : '' })), 1), _c = _b[0], questions = _c.questions, name = _c.name, team = _c.team, createdAt = _c.createdAt, createdBy = _c.createdBy, updatedAt = _c.updatedAt, updatedBy = _c.updatedBy;
    var resetApplicationFormDetail = (0, recoil_1.useResetRecoilState)((0, applicationForm_1.$applicationFormDetail)({ id: id !== null && id !== void 0 ? id : '' }));
    var methods = (0, react_hook_form_1.useForm)({ defaultValues: { questions: questions } });
    var handleRemoveQuestion = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!id) {
                    return [2 /*return*/];
                }
                set((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog), {
                    key: store_1.ModalKey.alertModalDialog,
                    isOpen: true,
                    props: {
                        heading: '삭제하시겠습니까?',
                        paragraph: '작성 또는 수정하신 데이터가 삭제됩니다.',
                        confirmButtonLabel: '삭제',
                        handleClickConfirmButton: function () {
                            (0, utils_1.request)({
                                requestFunc: function () {
                                    setIsLoading(true);
                                    return api.deleteApplicationForm(id);
                                },
                                errorHandler: handleAddToast,
                                onSuccess: function () {
                                    handleAddToast({
                                        type: Toast_component_1.ToastType.success,
                                        message: '성공적으로 지원서 설문지를 삭제했습니다.',
                                    });
                                    refreshSelectorFamilyByKey('applicationForms');
                                    navigate(constants_1.PATH.APPLICATION_FORM);
                                },
                                onCompleted: function () {
                                    setIsLoading(false);
                                    set((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog), {
                                        key: store_1.ModalKey.alertModalDialog,
                                        isOpen: false,
                                    });
                                },
                            });
                        },
                    },
                });
                return [2 /*return*/];
            });
        }); };
    });
    (0, hooks_1.useUnmount)(function () {
        resetApplicationFormDetail();
    });
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, __assign({}, methods, { children: (0, jsx_runtime_1.jsxs)(Styled.ApplicationFormDetailPage, { children: [(0, jsx_runtime_1.jsx)(components_1.ApplicationFormSection, { headline: "\uC9C0\uC6D0\uC11C \uC124\uBB38\uC9C0 \uC0C1\uC138" }, void 0), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("article", { children: [(0, jsx_runtime_1.jsxs)(Styled.Content, { children: [(0, jsx_runtime_1.jsx)("span", { children: "\uC9C0\uC6D0\uC124\uBB38\uC9C0 \uBB38\uC11C\uBA85" }, void 0), (0, jsx_runtime_1.jsx)("span", { children: name }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(Styled.QuestionContent, { children: questions.map(function (question, index) {
                                        var readableIndex = index + 1;
                                        var props = {
                                            label: "".concat(readableIndex, ". ").concat(question.content),
                                            description: question.description,
                                            disabled: true,
                                            required: question.required,
                                        };
                                        return ((0, jsx_runtime_1.jsx)("li", { children: question.questionType === types_1.QuestionKind.multiLineText ? ((0, jsx_runtime_1.jsx)(Styled.CustomTextarea, __assign({}, props, { placeholder: "\uC7A5\uBB38\uD615 \uD14D\uC2A4\uD2B8\uC785\uB2C8\uB2E4." }), void 0)) : ((0, jsx_runtime_1.jsx)(Styled.CustomInput, __assign({}, props, { "$size": Input_component_1.InputSize.md, placeholder: "\uB2E8\uB2F5\uD615 \uD14D\uC2A4\uD2B8\uC785\uB2C8\uB2E4." }), void 0)) }, question.questionId));
                                    }) }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(components_1.ApplicationFormAside, { platform: team.name, createdAt: createdAt, createdBy: createdBy, updatedAt: updatedAt, updatedBy: updatedBy, leftActionButton: {
                                text: '삭제',
                                onClick: handleRemoveQuestion,
                                isLoading: isLoading,
                            }, rightActionButton: {
                                text: '수정',
                                onClick: function () { return navigate("/application-form/update/".concat(id)); },
                            } }, void 0)] }, void 0)] }, void 0) }), void 0));
};
exports.default = ApplicationFormDetail;
