"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeWrongAmpString = exports.decodeHTMLEntities = exports.parsePlaceholderWhenInvalidDate = exports.parsePlaceholderWhenEmpty = exports.sortString = void 0;
var constants_1 = require("@/constants");
var sortString = function (type, one, another) {
    var compared = one < another;
    if (type === constants_1.SORT_TYPE.ASC) {
        return compared ? -1 : 1;
    }
    return compared ? 1 : -1;
};
exports.sortString = sortString;
var parsePlaceholderWhenEmpty = function (value, parsingText) {
    if (parsingText === void 0) { parsingText = '-'; }
    if (!value) {
        return parsingText;
    }
    return value;
};
exports.parsePlaceholderWhenEmpty = parsePlaceholderWhenEmpty;
var parsePlaceholderWhenInvalidDate = function (value, parsingText) {
    if (parsingText === void 0) { parsingText = '-'; }
    if (!value || value === 'Invalid Date') {
        return parsingText;
    }
    return value;
};
exports.parsePlaceholderWhenInvalidDate = parsePlaceholderWhenInvalidDate;
var decodeHTMLEntities = function (value) {
    var _a;
    var element = document.createElement('div');
    element.innerHTML = value;
    element.remove();
    return (_a = element.textContent) !== null && _a !== void 0 ? _a : '';
};
exports.decodeHTMLEntities = decodeHTMLEntities;
/** 백엔드 이스케이프 과정에서 `&`를 `&amp;`로 응답하기 때문에 프론트에서 임시로 처리 */
var removeWrongAmpString = function (value) {
    return value.replace(/&amp;/g, '&');
};
exports.removeWrongAmpString = removeWrongAmpString;
