"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var react_2 = require("react");
var useScript = function (url) {
    (0, react_2.useEffect)(function () {
        var script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
        return function () {
            document.body.removeChild(script);
        };
    }, [url]);
};
exports.default = useScript;
