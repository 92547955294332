"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var recoil_1 = require("recoil");
var react_hook_form_1 = require("react-hook-form");
var components_1 = require("@/components");
var Styled = __importStar(require("./DisplayQRCodeModalDialog.styled"));
var store_1 = require("@/store");
var Input_component_1 = require("@/components/common/Input/Input.component");
var CreateQRCodeModalDialog_component_1 = require("../CreateQRCodeModalDialog/CreateQRCodeModalDialog.component");
var utils_1 = require("@/utils");
var DisplayQRCodeModalDialog = function (_a) {
    var qrCodeUrl = _a.qrCodeUrl;
    var handleQRCodeModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.displayQRCodeModalDialog));
    var register = (0, react_hook_form_1.useForm)().register;
    var filteredUrl = (0, utils_1.decodeHTMLEntities)(qrCodeUrl);
    var props = {
        heading: 'QR URL 복사',
        handleCloseModal: function () {
            handleQRCodeModal({
                key: store_1.ModalKey.displayQRCodeModalDialog,
                isOpen: false,
            });
        },
        isContentScroll: false,
    };
    return ((0, jsx_runtime_1.jsx)(components_1.ModalWrapper, __assign({}, props, { className: CreateQRCodeModalDialog_component_1.QRCodeModalClassName, closeButtonHidden: true }, { children: (0, jsx_runtime_1.jsxs)(Styled.Wrapper, { children: [(0, jsx_runtime_1.jsxs)(Styled.URLInputLabel, { children: ["URL", (0, jsx_runtime_1.jsx)(Styled.RequiredDot, {}, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(components_1.InputField, __assign({ "$size": Input_component_1.InputSize.md, type: "text", value: filteredUrl, disabled: true }, register("display.qrcode.url")), void 0)] }, void 0) }), void 0));
};
exports.default = DisplayQRCodeModalDialog;
