"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var store_1 = require("@/store");
var constants_1 = require("@/constants");
var hooks_1 = require("@/hooks");
var Styled = __importStar(require("./EmailSendDetailListModalDialog.styled"));
var utils_1 = require("@/utils");
var string_1 = require("../../../utils/string");
var columns = [
    {
        title: '이름',
        accessor: 'applicant.name',
        idAccessor: 'applicationId',
        widthRatio: '30%',
    },
    {
        title: '이메일',
        accessor: 'applicant.email',
        widthRatio: '40%',
    },
    {
        title: '지원플랫폼',
        accessor: 'team.name',
        widthRatio: '30%',
    },
];
var EmailSendDetailListModalDialog = function (_a) {
    var selectedApplications = _a.selectedApplications;
    var _b = (0, hooks_1.usePagination)({
        totalCount: selectedApplications.length,
        pagingSize: 10,
        pageButtonsSize: 7,
        usingSearchParams: false,
    }), pageOptions = _b.pageOptions, handleChangePage = _b.handleChangePage;
    var _c = __read((0, react_2.useState)(selectedApplications), 2), tableRows = _c[0], setTableRows = _c[1];
    var pagedRows = (0, react_2.useMemo)(function () {
        var startIndex = 10 * (pageOptions.currentPage - 1);
        var endIndex = startIndex + 10;
        return tableRows.slice(startIndex, endIndex);
    }, [tableRows, pageOptions]);
    var _d = __read((0, react_2.useState)([
        { accessor: 'applicant.name', type: constants_1.SORT_TYPE.DEFAULT },
        { accessor: 'team.name', type: constants_1.SORT_TYPE.DEFAULT },
    ]), 2), sortTypes = _d[0], setSortTypes = _d[1];
    var handleRemoveCurrentModal = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set;
        return function () {
            set((0, store_1.$modalByStorage)(store_1.ModalKey.emailSendDetailListModalDialog), {
                key: store_1.ModalKey.emailSendDetailListModalDialog,
                isOpen: false,
            });
        };
    });
    var modalProps = {
        heading: '발송인원 상세 리스트',
        footer: {
            cancelButton: {
                label: '닫기',
            },
        },
        handleCloseModal: handleRemoveCurrentModal,
        isContentScroll: false,
        closeOnClickOverlay: false,
    };
    return ((0, jsx_runtime_1.jsx)(components_1.ModalWrapper, __assign({}, modalProps, { children: (0, jsx_runtime_1.jsx)(Styled.TableWrapper, { children: (0, jsx_runtime_1.jsx)(components_1.Table, { prefix: "application-email", columns: columns, rows: pagedRows.length > 0 ? pagedRows : tableRows, supportBar: {
                    totalCount: selectedApplications.length,
                    totalSummaryText: '총 발송인원',
                }, sortOptions: {
                    sortTypes: sortTypes,
                    disableMultiSort: true,
                    handleSortColumn: function (_sortTypes) {
                        var pivotColumn = _sortTypes.find(function (sortType) { return sortType.type !== constants_1.SORT_TYPE.DEFAULT; });
                        if (!pivotColumn) {
                            setTableRows(selectedApplications);
                        }
                        else {
                            setTableRows(__spreadArray([], __read(tableRows), false).sort(function (one, another) {
                                return (0, string_1.sortString)(pivotColumn.type, (0, utils_1.getOwnValueByKey)(one, pivotColumn.accessor), (0, utils_1.getOwnValueByKey)(another, pivotColumn.accessor));
                            }));
                        }
                        setSortTypes(_sortTypes);
                    },
                }, pagination: (0, jsx_runtime_1.jsx)(components_1.Pagination, { pageOptions: pageOptions, handleChangePage: handleChangePage }, void 0) }, void 0) }, void 0) }), void 0));
};
exports.default = EmailSendDetailListModalDialog;
