"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var recoil_1 = require("recoil");
var UserProfile_component_1 = __importStar(require("@/components/common/UserProfile/UserProfile.component"));
var __1 = require("..");
var Styled = __importStar(require("./MessageListPanel.styled"));
var components_1 = require("@/components");
var types_1 = require("@/types");
var store_1 = require("@/store");
var date_1 = require("@/utils/date");
var MessageInfo = function (_a) {
    var memo = _a.memo, emailRequestStatus = _a.emailRequestStatus, sendAt = _a.sendAt, senderPosition = _a.senderPosition, templateName = _a.templateName;
    return ((0, jsx_runtime_1.jsxs)(Styled.MessageInfoContainer, { children: [(0, jsx_runtime_1.jsx)(Styled.Label, __assign({ status: emailRequestStatus }, { children: types_1.EmailStatus[emailRequestStatus] }), void 0), (0, jsx_runtime_1.jsxs)(Styled.TitleContainer, { children: [(0, jsx_runtime_1.jsx)("div", { children: memo }, void 0), (0, jsx_runtime_1.jsx)("div", { children: (0, date_1.formatDate)(sendAt, 'YYYY년 M월 D일(ddd) a hh시 mm분') }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(__1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uC774\uBA54\uC77C" }, { children: "recruit.mashup@gmail.com" }), void 0), (0, jsx_runtime_1.jsx)(__1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uC790" }, { children: (0, jsx_runtime_1.jsx)(UserProfile_component_1.default, { team: (0, UserProfile_component_1.splitMemberPosition)(senderPosition)[0], role: (0, UserProfile_component_1.splitMemberPosition)(senderPosition)[1], showBackground: false, removePadding: true }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(__1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uB0B4\uC6A9" }, { children: types_1.EmailTypes[templateName] }), void 0)] }, void 0));
};
var MessageListPanel = function (_a) {
    var application = _a.application;
    var handleControlModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.emailSendModalDialog));
    var messageItems = application.applicationEmailResponses;
    return ((0, jsx_runtime_1.jsxs)(Styled.MessageListPanelContainer, { children: [(0, jsx_runtime_1.jsxs)(Styled.MessageListPanelTitle, { children: [(0, jsx_runtime_1.jsx)("h3", { children: "\uC774\uBA54\uC77C \uBC1C\uC1A1\uB0B4\uC5ED" }, void 0), (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": "xs", shape: "defaultLine", onClick: function () {
                            return handleControlModal({
                                key: store_1.ModalKey.emailSendModalDialog,
                                props: {
                                    selectedApplications: [application],
                                },
                                isOpen: true,
                            });
                        } }, { children: "\uC774\uBA54\uC77C \uBC1C\uC1A1" }), void 0)] }, void 0), messageItems.length > 0 && ((0, jsx_runtime_1.jsx)(Styled.MessageListPanelContent, { children: messageItems.map(function (each) { return ((0, jsx_runtime_1.jsx)(MessageInfo, __assign({}, each), each === null || each === void 0 ? void 0 : each.emailRequestId)); }) }, void 0))] }, void 0));
};
exports.default = MessageListPanel;
