"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
var useConvertTextToLink = function (content) {
    var handleLineBreak = function (text) {
        return text.split('\n').map(function (each) { return (each ? (0, jsx_runtime_1.jsx)("p", { children: each }, void 0) : (0, jsx_runtime_1.jsx)("br", {}, void 0)); });
    };
    return content.split(URL_REGEX).map(function (each) {
        if (each.match(URL_REGEX)) {
            return ((0, jsx_runtime_1.jsx)("a", __assign({ href: each, target: "_blank", rel: "noopener noreferrer" }, { children: each }), void 0));
        }
        return handleLineBreak(each);
    });
};
exports.default = useConvertTextToLink;
