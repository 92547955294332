"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_router_dom_1 = require("react-router-dom");
var Styled = __importStar(require("./TeamNavigationTabs.styled"));
var TEAMS = ['All', 'Design', 'Android', 'iOS', 'Web', 'Node', 'Spring'];
var TeamNavigationTabs = function (_a) {
    var allAltText = _a.allAltText;
    var _b = __read((0, react_router_dom_1.useSearchParams)(), 2), searchParams = _b[0], setSearchParams = _b[1];
    var handleClickTab = function (teamParam) {
        if (teamParam) {
            searchParams.set('team', teamParam);
            searchParams.delete('page');
        }
        else {
            searchParams.delete('team');
        }
        setSearchParams(searchParams);
    };
    return ((0, jsx_runtime_1.jsx)(Styled.Tabs, { children: TEAMS.map(function (team, index) {
            var teamParam = team === 'All' ? '' : team.toUpperCase();
            var active = team === 'All' ? !searchParams.has('team') : searchParams.get('team') === teamParam;
            var isAllAltText = team === 'All' && !!allAltText;
            return ((0, jsx_runtime_1.jsx)(Styled.Tab, __assign({ role: "link", "aria-label": "".concat(team, " tab"), "aria-current": active, active: active, isAllAltText: isAllAltText, onClick: function () { return handleClickTab(teamParam); } }, { children: isAllAltText ? allAltText : team }), index));
        }) }, void 0));
};
exports.default = TeamNavigationTabs;
