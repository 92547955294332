"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var components_1 = require("@/components");
var Styled = __importStar(require("./EditorAside.styled"));
var Button_component_1 = require("@/components/common/Button/Button.component");
var utils_1 = require("@/utils");
var EditorAside = function (_a) {
    var platform = _a.platform, rightActionButton = _a.rightActionButton;
    return ((0, jsx_runtime_1.jsxs)(Styled.EditorAside, { children: [(0, jsx_runtime_1.jsx)(Styled.Headline, { children: "\uC218\uC815 \uC815\uBCF4 \uD655\uC778" }, void 0), (0, jsx_runtime_1.jsxs)(Styled.ContentContainer, { children: [(0, jsx_runtime_1.jsx)(Styled.Label, { children: "\uD50C\uB7AB\uD3FC" }, void 0), (0, jsx_runtime_1.jsx)(Styled.Content, { children: platform }, void 0)] }, void 0), (0, jsx_runtime_1.jsxs)(Styled.ContentContainer, { children: [(0, jsx_runtime_1.jsx)(Styled.Label, { children: "\uC791\uC131\uC77C\uC2DC" }, void 0), (0, jsx_runtime_1.jsx)(Styled.Content, { children: (0, utils_1.formatDate)(new Date(), 'YYYY년 M월 D일 A h시 m분') }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(Styled.Divider, {}, void 0), (0, jsx_runtime_1.jsx)(Styled.ButtonContainer, { children: (0, jsx_runtime_1.jsx)(components_1.Button, __assign({}, rightActionButton, { "$size": Button_component_1.ButtonSize.sm, shape: Button_component_1.ButtonShape.primary, label: rightActionButton === null || rightActionButton === void 0 ? void 0 : rightActionButton.text }), void 0) }, void 0)] }, void 0));
};
exports.default = EditorAside;
