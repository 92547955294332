"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_router_dom_1 = require("react-router-dom");
var useHistory = function (shouldClearQueryString) {
    var _a;
    if (shouldClearQueryString === void 0) { shouldClearQueryString = true; }
    var navigate = (0, react_router_dom_1.useNavigate)();
    var location = (0, react_router_dom_1.useLocation)();
    var search = (location !== null && location !== void 0 ? location : {}).search;
    var from = ((_a = location.state) !== null && _a !== void 0 ? _a : {}).from;
    var handleNavigate = function (to) {
        var currentPath = location.pathname;
        var currentPathWithQueryString = "".concat(currentPath).concat(search);
        var fromPath = shouldClearQueryString ? currentPath : currentPathWithQueryString;
        navigate(to, {
            state: {
                from: fromPath,
            },
        });
    };
    var handleGoBack = function (defaultPath) {
        if (from) {
            navigate(from);
            return;
        }
        if (defaultPath) {
            navigate(defaultPath);
            return;
        }
        navigate(-1);
    };
    return {
        handleNavigate: handleNavigate,
        handleGoBack: handleGoBack,
    };
};
exports.default = useHistory;
