"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLocalStorageData = exports.getLocalStorageData = void 0;
var getLocalStorageData = function (key) {
    var _a;
    return JSON.parse((_a = localStorage.getItem(key)) !== null && _a !== void 0 ? _a : '{}');
};
exports.getLocalStorageData = getLocalStorageData;
var setLocalStorageData = function (key, data) {
    localStorage.setItem(key, JSON.stringify(data));
};
exports.setLocalStorageData = setLocalStorageData;
