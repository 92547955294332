"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var react_2 = require("react");
var react_router_dom_1 = require("react-router-dom");
var Pagination_component_1 = require("@/components/common/Pagination/Pagination.component");
var DEFAULT_PAGING_SIZE = 20;
var DEFAULT_PAGE_BUTTONS_SIZE = 10;
var getPageOptions = function (_a) {
    var totalCount = _a.totalCount, pagingSize = _a.pagingSize, pageIndex = _a.pageIndex, pageButtonsSize = _a.pageButtonsSize;
    var totalPages = Math.ceil(totalCount / pagingSize);
    var startPage = Math.floor(pageIndex / pageButtonsSize) * pageButtonsSize;
    var endPage = startPage + pageButtonsSize;
    return {
        currentPage: pageIndex + 1,
        startPage: startPage + 1,
        endPage: endPage > totalPages ? totalPages : endPage,
        totalPages: totalPages,
        pagingSize: pagingSize,
    };
};
var usePagination = function (_a) {
    var totalCount = _a.totalCount, _b = _a.usingSearchParams, usingSearchParams = _b === void 0 ? true : _b, _c = _a.pagingSize, pagingSize = _c === void 0 ? DEFAULT_PAGING_SIZE : _c, _d = _a.pageButtonsSize, pageButtonsSize = _d === void 0 ? DEFAULT_PAGE_BUTTONS_SIZE : _d;
    var _e = __read((0, react_router_dom_1.useSearchParams)(), 2), searchParams = _e[0], setSearchParams = _e[1];
    var _f = __read((0, react_2.useState)({
        currentPage: 0,
        startPage: 0,
        endPage: 0,
        totalPages: 0,
        pagingSize: 0,
    }), 2), pageOptions = _f[0], setPageOptions = _f[1];
    var handleChangePage = function (page) {
        if (page === 0)
            return;
        var newPageOptions = getPageOptions({
            totalCount: totalCount,
            pageIndex: page - 1,
            pagingSize: pageOptions.pagingSize,
            pageButtonsSize: pageButtonsSize,
        });
        var currentPage = newPageOptions.currentPage;
        if (usingSearchParams) {
            searchParams.set('page', currentPage.toString());
            setSearchParams(searchParams);
        }
        else {
            setPageOptions(newPageOptions);
        }
    };
    var handleChangeSize = function (_pagingSize) {
        if (usingSearchParams) {
            searchParams.set('size', _pagingSize);
            setSearchParams(searchParams);
        }
        else {
            var newPageOptions = getPageOptions({
                totalCount: totalCount,
                pageIndex: pageOptions.currentPage - 1,
                pagingSize: parseInt(_pagingSize, 10),
                pageButtonsSize: pageButtonsSize,
            });
            var currentPage = newPageOptions.currentPage, endPage = newPageOptions.endPage;
            if (currentPage > endPage) {
                newPageOptions = __assign(__assign({}, newPageOptions), { currentPage: newPageOptions.endPage });
            }
            setPageOptions(newPageOptions);
        }
    };
    (0, react_2.useEffect)(function () {
        if (!totalCount)
            return;
        var currentPage = searchParams.get('page');
        var currentSize = usingSearchParams ? searchParams.get('size') : '';
        var newPageOptions = getPageOptions({
            totalCount: totalCount,
            pageIndex: currentPage ? parseInt(currentPage, 10) - 1 : Pagination_component_1.FIRST_PAGE - 1,
            pagingSize: currentSize ? parseInt(currentSize, 10) : pagingSize,
            pageButtonsSize: pageButtonsSize,
        });
        if (usingSearchParams && newPageOptions.currentPage > newPageOptions.endPage) {
            setSearchParams({
                page: newPageOptions.endPage.toString(),
                size: newPageOptions.pagingSize.toString(),
            });
            return;
        }
        setPageOptions(newPageOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount, searchParams]);
    return {
        pageOptions: pageOptions,
        handleChangePage: handleChangePage,
        handleChangeSize: handleChangeSize,
    };
};
exports.default = usePagination;
