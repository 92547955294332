"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var Button_component_1 = require("@/components/common/Button/Button.component");
var utils_1 = require("@/utils");
var api = __importStar(require("@/api"));
var hooks_1 = require("@/hooks");
var styles_1 = require("@/styles");
var store_1 = require("@/store");
var AdminMemberList = function () {
    var handleHelperMemberModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.createHelperAdminMemberDialog));
    var _a = __read((0, react_2.useState)([]), 2), adminMembers = _a[0], setAdminMembers = _a[1];
    var _b = __read((0, react_2.useState)([]), 2), selectedRows = _b[0], setSelectedRows = _b[1];
    var selectedResults = (0, react_2.useMemo)(function () { return (0, utils_1.uniqArray)(selectedRows.map(function (row) { return row.adminMemberId; })); }, [selectedRows]);
    var handleAddToast = (0, hooks_1.useToast)().handleAddToast;
    var getAdminMembers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.getAdminMembers()];
                case 1:
                    data = (_a.sent()).data;
                    setAdminMembers(data);
                    return [2 /*return*/];
            }
        });
    }); };
    (0, react_2.useEffect)(function () {
        getAdminMembers();
    }, []);
    var totalCount = adminMembers === null || adminMembers === void 0 ? void 0 : adminMembers.length;
    var columns = [
        {
            title: '이름',
            widthRatio: '50%',
            accessor: 'username',
        },
        {
            title: '포지션',
            widthRatio: '50%',
            accessor: 'position',
        },
    ];
    var handleSelectAll = (0, react_2.useCallback)(function (checkedValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (checkedValue) {
                setSelectedRows([]);
                return [2 /*return*/];
            }
            setSelectedRows(adminMembers);
            return [2 /*return*/];
        });
    }); }, [adminMembers]);
    var handleResetPassword = (0, recoil_1.useRecoilCallback)(function () { return function () {
        (0, utils_1.request)({
            requestFunc: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.resetAdminMembersPassword(selectedResults)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); },
            onSuccess: function () {
                handleAddToast({
                    type: styles_1.ToastType.success,
                    message: '성공적으로 비밀번호가 리셋되었습니다.',
                });
                setSelectedRows([]);
                getAdminMembers();
            },
            errorHandler: handleAddToast,
        });
    }; });
    var handleRemove = (0, recoil_1.useRecoilCallback)(function () { return function () {
        (0, utils_1.request)({
            requestFunc: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.deleteAdminMembers(selectedResults)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); },
            onSuccess: function () {
                handleAddToast({
                    type: styles_1.ToastType.success,
                    message: '성공적으로 삭제되었습니다.',
                });
                setSelectedRows([]);
                getAdminMembers();
            },
            errorHandler: handleAddToast,
        });
    }; });
    return ((0, jsx_runtime_1.jsx)(components_1.Table, { prefix: "admin-members", topStickyHeight: 0, columns: columns, rows: adminMembers, supportBar: {
            totalSummaryText: '총 인원',
            selectedSummaryText: '명 선택',
            totalCount: totalCount,
            buttons: [
                (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": Button_component_1.ButtonSize.xs, shape: Button_component_1.ButtonShape.defaultLine, onClick: function () {
                        return handleHelperMemberModal({
                            key: store_1.ModalKey.createHelperAdminMemberDialog,
                            props: { refreshList: getAdminMembers },
                            isOpen: true,
                        });
                    } }, { children: "\uD5EC\uD37C \uCD94\uAC00" }), void 0),
                (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": Button_component_1.ButtonSize.xs, shape: Button_component_1.ButtonShape.defaultLine, onClick: handleResetPassword, disabled: selectedResults.length === 0 }, { children: "\uBE44\uBC00\uBC88\uD638 \uCD08\uAE30\uD654" }), void 0),
                (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": Button_component_1.ButtonSize.xs, shape: Button_component_1.ButtonShape.defaultLine, onClick: handleRemove, disabled: selectedResults.length === 0 }, { children: "\uC0AD\uC81C" }), void 0),
            ],
        }, selectableRow: {
            selectedCount: selectedRows.length,
            selectedRows: selectedRows,
            setSelectedRows: setSelectedRows,
            handleSelectAll: handleSelectAll,
        } }, void 0));
};
exports.default = AdminMemberList;
