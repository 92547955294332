"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.select = void 0;
var react_1 = require("@emotion/react");
var Select_component_1 = require("@/components/common/Select/Select.component");
var fonts_1 = require("./fonts");
exports.select = {
    size: (_a = {},
        _a[Select_component_1.SelectSize.xs] = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", ";\n\n      height: 3.6rem;\n    "], ["\n      ", ";\n\n      height: 3.6rem;\n    "])), fonts_1.fonts.regular13),
        _a[Select_component_1.SelectSize.sm] = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      ", ";\n\n      height: 4rem;\n    "], ["\n      ", ";\n\n      height: 4rem;\n    "])), fonts_1.fonts.regular15),
        _a[Select_component_1.SelectSize.md] = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      ", ";\n\n      height: 4.8rem;\n    "], ["\n      ", ";\n\n      height: 4.8rem;\n    "])), fonts_1.fonts.regular15),
        _a),
};
var templateObject_1, templateObject_2, templateObject_3;
