"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
var a11y_1 = require("./a11y");
var colors_1 = require("./colors");
var fonts_1 = require("./fonts");
var button_1 = require("./button");
var input_1 = require("./input");
var zIndex_1 = require("./zIndex");
var userProfile_1 = require("./userProfile");
var navigation_1 = require("./navigation");
var badge_1 = require("./badge");
var select_1 = require("./select");
var toast_1 = require("./toast");
exports.theme = {
    a11y: a11y_1.a11y,
    fonts: fonts_1.fonts,
    colors: colors_1.colors,
    button: button_1.button,
    input: input_1.input,
    zIndex: zIndex_1.zIndex,
    userProfile: userProfile_1.userProfile,
    navigation: navigation_1.navigation,
    badge: badge_1.badge,
    select: select_1.select,
    toast: toast_1.toast,
};
