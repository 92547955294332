"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var react_router_dom_1 = require("react-router-dom");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var Styled = __importStar(require("./ScheduleList.styled"));
var date_1 = require("@/utils/date");
var constants_1 = require("@/constants");
var Button_component_1 = require("@/components/common/Button/Button.component");
var hooks_1 = require("@/hooks");
var store_1 = require("@/store");
var schedule_1 = require("@/store/schedule");
var utils_1 = require("@/utils");
var styles_1 = require("@/styles");
var ScheduleList = function () {
    var _a, _b;
    var _c = __read((0, react_router_dom_1.useSearchParams)(), 2), searchParams = _c[0], setSearchParams = _c[1];
    var teamName = searchParams.get('team');
    var generationNumber = (0, recoil_1.useRecoilValue)(store_1.$generationNumber);
    var _d = __read((0, recoil_1.useRecoilValue)(store_1.$profile), 1), position = _d[0];
    var isMaster = (0, recoil_1.useRecoilValue)(store_1.$isMaster);
    var page = searchParams.get('page') || '1';
    var size = searchParams.get('size') || '10';
    var searchWord = searchParams.get('searchWord') || '';
    var _e = (0, react_router_dom_1.useLocation)(), pathname = _e.pathname, search = _e.search;
    var handleAddToast = (0, hooks_1.useToast)().handleAddToast;
    var handleClickLink = function (event) {
        if (!isMaster && teamName !== position) {
            handleAddToast({
                type: styles_1.ToastType.error,
                message: '접근 권한이 없는 플랫폼입니다.',
            });
            event.preventDefault();
        }
    };
    var columns = [
        {
            title: '스케줄 명',
            accessor: 'name',
            widthRatio: '20%',
            idAccessor: 'scheduleId',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue, id = _a.id;
                return ((0, jsx_runtime_1.jsx)(Styled.TitleLink, __assign({ to: (0, constants_1.getScheduleDetailPage)(id), state: { from: "".concat(pathname).concat(search) }, onClick: handleClickLink }, { children: cellValue }), void 0));
            },
        },
        {
            title: '스케줄 일시',
            accessor: 'startedAt',
            widthRatio: '20%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue;
                return (0, date_1.formatDate)(cellValue, 'YYYY년 M월 D일 A h시 m분');
            },
        },
        {
            title: '등록 일시',
            accessor: 'createdAt',
            widthRatio: '20%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue;
                return (0, date_1.formatDate)(cellValue, 'YYYY년 M월 D일 A h시 m분');
            },
        },
        {
            title: '배포 일시',
            accessor: 'publishedAt',
            widthRatio: '20%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue;
                return (0, date_1.formatDate)(cellValue, 'YYYY년 M월 D일 A h시 m분');
            },
        },
        {
            title: '배포 상태',
            accessor: 'status',
            widthRatio: '20%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue;
                var value = cellValue;
                return (0, utils_1.getScheduleStatusText)(value);
            },
        },
    ];
    var scheduleParams = (0, react_2.useMemo)(function () {
        var _a;
        return ({
            page: parseInt(page, 10) - 1,
            size: parseInt(size, 10),
            searchWord: searchWord,
            generationNumber: generationNumber,
            scheduleType: (_a = teamName) !== null && _a !== void 0 ? _a : 'ALL',
        });
    }, [generationNumber, page, searchWord, size, teamName]);
    var _f = __read((0, recoil_1.useRecoilStateLoadable)((0, schedule_1.$schedules)(scheduleParams)), 1), contents = _f[0].contents;
    var tableRows = (_a = contents.data) !== null && _a !== void 0 ? _a : [];
    var _g = ((_b = contents.page) !== null && _b !== void 0 ? _b : {}).totalCount, totalCount = _g === void 0 ? 0 : _g;
    var _h = (0, hooks_1.usePagination)({
        totalCount: totalCount,
        pagingSize: 10,
    }), pageOptions = _h.pageOptions, handleChangePage = _h.handleChangePage, handleChangeSize = _h.handleChangeSize;
    (0, react_2.useEffect)(function () {
        searchParams.delete('searchWord');
        setSearchParams(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamName]);
    (0, react_2.useEffect)(function () {
        searchParams.delete('page');
        setSearchParams(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generationNumber]);
    return ((0, jsx_runtime_1.jsxs)(Styled.PageWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.Heading, { children: "\uC2A4\uCF00\uC904 \uC815\uBCF4 " }, void 0), (0, jsx_runtime_1.jsxs)(Styled.StickyContainer, { children: [(0, jsx_runtime_1.jsx)(components_1.TeamNavigationTabs, { allAltText: "All Seminar" }, void 0), (0, jsx_runtime_1.jsx)(components_1.SearchOptionBar, { placeholder: "\uC2A4\uCF00\uC904\uBA85 \uAC80\uC0C9" }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(components_1.Table, { prefix: "schedule", topStickyHeight: 9.2, columns: columns, rows: tableRows, supportBar: {
                    totalCount: totalCount,
                    totalSummaryText: '총 개수',
                    buttons: [
                        (0, jsx_runtime_1.jsx)(components_1.Link, __assign({ to: constants_1.PATH.SCHEDULE_CREATE }, { children: (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": Button_component_1.ButtonSize.xs, shape: Button_component_1.ButtonShape.defaultLine }, { children: "\uC2A4\uCF00\uC904 \uCD94\uAC00" }), void 0) }), void 0),
                    ],
                }, pagination: (0, jsx_runtime_1.jsx)(components_1.Pagination, { pageOptions: pageOptions, selectableSize: {
                        selectBoxPosition: tableRows.length > 3 ? 'top' : 'bottom',
                        handleChangeSize: handleChangeSize,
                    }, handleChangePage: handleChangePage }, void 0) }, void 0), (0, jsx_runtime_1.jsx)(components_1.BottomCTA, __assign({ boundaries: {
                    visibility: { topHeight: 179, bottomHeight: 20 },
                    noAnimation: { bottomHeight: 20 },
                } }, { children: (0, jsx_runtime_1.jsx)(components_1.Pagination, { pageOptions: pageOptions, selectableSize: {
                        selectBoxPosition: tableRows.length > 3 ? 'top' : 'bottom',
                        handleChangeSize: handleChangeSize,
                    }, handleChangePage: handleChangePage }, void 0) }), void 0)] }, void 0));
};
exports.default = ScheduleList;
