"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_router_dom_1 = require("react-router-dom");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var Styled = __importStar(require("./ScheduleDetail.styled"));
var store_1 = require("@/store");
var ScheduleDetail_1 = require("@/components/ScheduleDetail");
var hooks_1 = require("@/hooks");
var constants_1 = require("@/constants");
var schedule_1 = require("@/types/dto/schedule");
var utils_1 = require("@/utils");
var api = __importStar(require("@/api"));
var styles_1 = require("@/styles");
var ScheduleDetail = function () {
    var _a = (0, react_router_dom_1.useParams)().scheduleId, scheduleId = _a === void 0 ? '' : _a;
    var handleGoBack = (0, hooks_1.useHistory)().handleGoBack;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var handleAddToast = (0, hooks_1.useToast)().handleAddToast;
    var refreshSelectorFamilyByKey = (0, hooks_1.useRefreshSelectorFamilyByKey)();
    var _b = (0, recoil_1.useRecoilValue)((0, store_1.$scheduleDetail)({ scheduleId: scheduleId !== null && scheduleId !== void 0 ? scheduleId : '' })), name = _b.name, scheduleType = _b.scheduleType, generationNumber = _b.generationNumber, startedAt = _b.startedAt, createdAt = _b.createdAt, publishedAt = _b.publishedAt, status = _b.status, sessionList = _b.eventList, location = _b.location, notice = _b.notice;
    var isPublished = status === schedule_1.ScheduleStatus.PUBLIC;
    var handlePublishOrHideSchedule = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set, refresh = _a.refresh;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            var heading, toastMessage;
            return __generator(this, function (_a) {
                heading = isPublished ? '스케줄을 배포 취소하시겠습니까?' : '스케줄을 배포하시겠습니까?';
                toastMessage = isPublished
                    ? '성공적으로 스케줄의 배포를 취소했습니다'
                    : '성공적으로 스케줄을 배포하였습니다.';
                set((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog), {
                    key: store_1.ModalKey.alertModalDialog,
                    isOpen: true,
                    props: {
                        heading: heading,
                        confirmButtonLabel: '확인',
                        handleClickConfirmButton: function () {
                            (0, utils_1.request)({
                                requestFunc: function () {
                                    if (isPublished) {
                                        return api.hideSchedule(scheduleId);
                                    }
                                    return api.publishSchedule(scheduleId);
                                },
                                errorHandler: handleAddToast,
                                onSuccess: function () {
                                    handleAddToast({
                                        type: styles_1.ToastType.success,
                                        message: toastMessage,
                                    });
                                    refresh((0, store_1.$scheduleDetail)({ scheduleId: scheduleId !== null && scheduleId !== void 0 ? scheduleId : '' }));
                                    refreshSelectorFamilyByKey('schedules');
                                },
                                onCompleted: function () {
                                    set((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog), {
                                        key: store_1.ModalKey.alertModalDialog,
                                        isOpen: false,
                                    });
                                },
                            });
                        },
                    },
                });
                return [2 /*return*/];
            });
        }); };
    });
    var handleDeleteSchedule = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                set((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog), {
                    key: store_1.ModalKey.alertModalDialog,
                    isOpen: true,
                    props: {
                        heading: '스케줄을 삭제하시겠습니까?',
                        paragraph: '작성 또는 수정하신 데이터가 삭제됩니다.',
                        confirmButtonLabel: '삭제',
                        handleClickConfirmButton: function () {
                            (0, utils_1.request)({
                                requestFunc: function () {
                                    return api.deleteSchedule(scheduleId);
                                },
                                errorHandler: handleAddToast,
                                onSuccess: function () {
                                    handleAddToast({
                                        type: styles_1.ToastType.success,
                                        message: '성공적으로 스케줄을 삭제했습니다.',
                                    });
                                    refreshSelectorFamilyByKey('schedules');
                                    navigate(constants_1.PATH.SCHEDULE);
                                },
                                onCompleted: function () {
                                    set((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog), {
                                        key: store_1.ModalKey.alertModalDialog,
                                        isOpen: false,
                                    });
                                },
                            });
                        },
                    },
                });
                return [2 /*return*/];
            });
        }); };
    });
    return ((0, jsx_runtime_1.jsxs)(Styled.ScheduleDetailPage, { children: [(0, jsx_runtime_1.jsx)(components_1.BackButton, { onClick: function () { return handleGoBack(constants_1.PATH.SCHEDULE); } }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Header, { children: [(0, jsx_runtime_1.jsx)("h2", { children: "\uC2A4\uCF00\uC904 \uC0C1\uC138" }, void 0), (0, jsx_runtime_1.jsxs)(Styled.ButtonWrapper, { children: [(0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": "sm", shape: "defaultLine", disabled: isPublished, onClick: handleDeleteSchedule }, { children: "\uC0AD\uC81C" }), void 0), (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": "sm", shape: "primaryLine", onClick: function () { return navigate((0, constants_1.getScheduleUpdatePage)(scheduleId)); }, disabled: isPublished }, { children: "\uC218\uC815" }), void 0), (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": "sm", shape: "primary", onClick: handlePublishOrHideSchedule }, { children: isPublished ? '배포 취소' : '배포' }), void 0)] }, void 0)] }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Content, { children: [(0, jsx_runtime_1.jsx)("h3", { children: "\uC2A4\uCF00\uC904 \uC815\uBCF4" }, void 0), (0, jsx_runtime_1.jsx)(ScheduleDetail_1.ScheduleInfoList, { name: name, scheduleType: scheduleType, generationNumber: generationNumber, startedAt: startedAt, createdAt: createdAt, publishedAt: publishedAt, status: status, location: location, notice: notice }, void 0)] }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Content, { children: [(0, jsx_runtime_1.jsx)("h3", { children: "\uC138\uC158 \uC815\uBCF4" }, void 0), (0, jsx_runtime_1.jsx)(Styled.SessionList, { children: sessionList.map(function (session) { return ((0, jsx_runtime_1.jsx)(ScheduleDetail_1.SessionListItem, __assign({}, session, { scheduleId: Number(scheduleId) }), session.eventId)); }) }, void 0)] }, void 0)] }, void 0));
};
exports.default = ScheduleDetail;
