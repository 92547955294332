"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScheduleUpdatePage = exports.getScheduleDetailPage = exports.getApplicationFormDetailPage = exports.PATH = void 0;
exports.PATH = {
    LOGIN: '/login',
    APPLICATION: '/application',
    APPLICATION_DETAIL: '/application/:id',
    EMAIL: '/email',
    APPLICATION_FORM: '/application-form',
    APPLICATION_FORM_DETAIL: '/application-form/:id',
    APPLICATION_FORM_CREATE: '/application-form/create',
    APPLICATION_FORM_UPDATE: '/application-form/update/:id',
    ACTIVITY_SCORE: '/activity-score',
    ACTIVITY_SCORE_DETAIL: '/activity-score/:generationNumber/:memberId',
    SCHEDULE: '/schedule',
    SCHEDULE_DETAIL: '/schedule/:scheduleId',
    SCHEDULE_CREATE: '/schedule/create',
    SCHEDULE_UPDATE: '/schedule/update/:scheduleId',
    UPDATE_PLATFORM_RECRUIT: '/recruit',
    ADMIN_MEMBERS: '/admin-members',
    SIGNUP_CODE: '/signup-code',
    RECRUIT: '/recruit',
    FAQ: '/faq',
    MY_PAGE: '/my-page',
    NOT_FOUND: '/404',
    FORBIDDEN: '/403',
};
var getApplicationFormDetailPage = function (applicationFormId) {
    return "/application-form/".concat(applicationFormId);
};
exports.getApplicationFormDetailPage = getApplicationFormDetailPage;
var getScheduleDetailPage = function (scheduleId) { return "/schedule/".concat(scheduleId); };
exports.getScheduleDetailPage = getScheduleDetailPage;
var getScheduleUpdatePage = function (scheduleId) {
    return "/schedule/update/".concat(scheduleId);
};
exports.getScheduleUpdatePage = getScheduleUpdatePage;
