"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.button = void 0;
var react_1 = require("@emotion/react");
var Button_component_1 = require("@/components/common/Button/Button.component");
var _1 = require(".");
exports.button = {
    size: (_a = {},
        _a[Button_component_1.ButtonSize.xs] = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", ";\n      min-width: 6rem;\n      height: 3.6rem;\n      padding: 0 1.1rem;\n      line-height: 1.9rem;\n      border-radius: 0.9rem;\n    "], ["\n      ", ";\n      min-width: 6rem;\n      height: 3.6rem;\n      padding: 0 1.1rem;\n      line-height: 1.9rem;\n      border-radius: 0.9rem;\n    "])), _1.fonts.medium13),
        _a[Button_component_1.ButtonSize.sm] = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      ", ";\n      min-width: 6.8rem;\n      height: 4rem;\n      padding: 0 1.1rem;\n      line-height: 2.2rem;\n      border-radius: 1rem;\n    "], ["\n      ", ";\n      min-width: 6.8rem;\n      height: 4rem;\n      padding: 0 1.1rem;\n      line-height: 2.2rem;\n      border-radius: 1rem;\n    "])), _1.fonts.medium15),
        _a[Button_component_1.ButtonSize.md] = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      ", ";\n      min-width: 9.6rem;\n      height: 4.8rem;\n      padding: 0 1.1rem;\n      line-height: 2.4rem;\n      border-radius: 1.2rem;\n    "], ["\n      ", ";\n      min-width: 9.6rem;\n      height: 4.8rem;\n      padding: 0 1.1rem;\n      line-height: 2.4rem;\n      border-radius: 1.2rem;\n    "])), _1.fonts.medium16),
        _a[Button_component_1.ButtonSize.lg] = (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      ", ";\n      min-width: 9.6rem;\n      height: 5.6rem;\n      padding: 0 1.1rem;\n      line-height: 2.4rem;\n      border-radius: 1.6rem;\n    "], ["\n      ", ";\n      min-width: 9.6rem;\n      height: 5.6rem;\n      padding: 0 1.1rem;\n      line-height: 2.4rem;\n      border-radius: 1.6rem;\n    "])), _1.fonts.medium16),
        _a),
    shape: (_b = {},
        _b[Button_component_1.ButtonShape.default] = (0, react_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      &:disabled {\n        background-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "], ["\n      color: ", ";\n      background-color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      &:disabled {\n        background-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "])), _1.colors.white, _1.colors.gray60, _1.colors.gray70, _1.colors.gray40, _1.colors.white),
        _b[Button_component_1.ButtonShape.defaultLine] = (0, react_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      &:disabled {\n        color: ", ";\n        background-color: ", ";\n        border-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "], ["\n      color: ", ";\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      &:disabled {\n        color: ", ";\n        background-color: ", ";\n        border-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "])), _1.colors.gray70, _1.colors.white, _1.colors.gray40, _1.colors.gray10, _1.colors.gray50, _1.colors.gray10, _1.colors.gray30, _1.colors.gray70),
        _b[Button_component_1.ButtonShape.primary] = (0, react_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      &:disabled {\n        background-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "], ["\n      color: ", ";\n      background-color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      &:disabled {\n        background-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "])), _1.colors.white, _1.colors.purple70, _1.colors.purple80, _1.colors.purple40, _1.colors.white),
        _b[Button_component_1.ButtonShape.primaryLine] = (0, react_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      &:disabled {\n        color: ", ";\n        background-color: ", ";\n        border-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "], ["\n      color: ", ";\n      background-color: ", ";\n      border-color: ", ";\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      &:disabled {\n        color: ", ";\n        background-color: ", ";\n        border-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "])), _1.colors.purple70, _1.colors.purple10, _1.colors.purple60, _1.colors.purple20, _1.colors.purple40, _1.colors.purple10, _1.colors.purple20, _1.colors.purple60),
        _b[Button_component_1.ButtonShape.smallIcon] = (0, react_1.css)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      width: 3.6rem;\n      min-width: auto;\n      height: 3.6rem;\n      padding: 0 0;\n      line-height: 0;\n      background-color: ", ";\n      border-color: ", ";\n      border-radius: 0.9rem;\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "], ["\n      width: 3.6rem;\n      min-width: auto;\n      height: 3.6rem;\n      padding: 0 0;\n      line-height: 0;\n      background-color: ", ";\n      border-color: ", ";\n      border-radius: 0.9rem;\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "])), _1.colors.white, _1.colors.gray40, _1.colors.gray10, _1.colors.gray60),
        _b[Button_component_1.ButtonShape.icon] = (0, react_1.css)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      width: 4.4rem;\n      min-width: auto;\n      height: 4.4rem;\n      padding: 0 0;\n      line-height: 0;\n      background-color: ", ";\n      border-color: ", ";\n      border-radius: 0.9rem;\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "], ["\n      width: 4.4rem;\n      min-width: auto;\n      height: 4.4rem;\n      padding: 0 0;\n      line-height: 0;\n      background-color: ", ";\n      border-color: ", ";\n      border-radius: 0.9rem;\n\n      &:hover {\n        background-color: ", ";\n      }\n\n      & > svg > path {\n        stroke: ", ";\n      }\n    "])), _1.colors.white, _1.colors.gray40, _1.colors.gray10, _1.colors.gray60),
        _b),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
