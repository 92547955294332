"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputSize = void 0;
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var Styled = __importStar(require("./Input.styled"));
exports.InputSize = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
};
var Input = function (_a, ref) {
    var id = _a.id, className = _a.className, $size = _a.$size, label = _a.label, errorMessage = _a.errorMessage, required = _a.required, description = _a.description, fill = _a.fill, endIcon = _a.endIcon, disabled = _a.disabled, onClick = _a.onClick, resetProps = __rest(_a, ["id", "className", "$size", "label", "errorMessage", "required", "description", "fill", "endIcon", "disabled", "onClick"]);
    return ((0, jsx_runtime_1.jsxs)(Styled.InputContainer, __assign({ className: className, fill: fill }, { children: [label && ((0, jsx_runtime_1.jsxs)(Styled.InputLabel, __assign({ htmlFor: id }, { children: [(0, jsx_runtime_1.jsx)("span", { children: label }, void 0), required && (0, jsx_runtime_1.jsx)(Styled.RequiredDot, {}, void 0)] }), void 0)), description && (0, jsx_runtime_1.jsx)(Styled.Description, { children: description }, void 0), (0, jsx_runtime_1.jsxs)(Styled.InputWrapper, __assign({ onClick: onClick, errorMessage: errorMessage, disabled: disabled }, { children: [(0, jsx_runtime_1.jsx)(Styled.Input, __assign({ "$size": $size, ref: ref, id: id, disabled: disabled }, resetProps), void 0), endIcon] }), void 0), errorMessage && (0, jsx_runtime_1.jsx)(Styled.InputErrorMessage, { children: errorMessage }, void 0)] }), void 0));
};
exports.default = (0, react_2.forwardRef)(Input);
