"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.userProfile = void 0;
var react_1 = require("@emotion/react");
var _1 = require(".");
// TODO:(용재) 추후 원인 파악
var Role = {
    leader: 'LEADER',
    subLeader: 'SUBLEADER',
    member: 'MEMBER',
};
exports.userProfile = {
    mashUp: (_a = {},
        _a[Role.leader] = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), _1.colors.red70),
        _a[Role.subLeader] = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), _1.colors.orange70),
        _a),
    platform: (_b = {},
        _b[Role.leader] = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), _1.colors.green70),
        _b[Role.subLeader] = (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), _1.colors.blue70),
        _b),
    branding: (_c = {},
        _c[Role.leader] = (0, react_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), _1.colors.green70),
        _c[Role.subLeader] = (0, react_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), _1.colors.blue70),
        _c[Role.member] = (0, react_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      background-color: ", ";\n    "], ["\n      background-color: ", ";\n    "])), _1.colors.gray70),
        _c),
    helper: (0, react_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    background-color: ", ";\n  "], ["\n    background-color: ", ";\n  "])), _1.colors.yellow70),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
