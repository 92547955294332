"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.badge = void 0;
var react_1 = require("@emotion/react");
var ApplicationStatusBadge_component_1 = require("@/components/common/ApplicationStatusBadge/ApplicationStatusBadge.component");
var _1 = require(".");
var types_1 = require("@/types");
exports.badge = (_a = {},
    _a[ApplicationStatusBadge_component_1.ApplicationResultStatus.NOT_RATED] = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.gray60, _1.colors.gray20),
    _a[ApplicationStatusBadge_component_1.ApplicationResultStatus.SCREENING_TO_BE_DETERMINED] = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.blue70, _1.colors.blue20),
    _a[ApplicationStatusBadge_component_1.ApplicationResultStatus.SCREENING_FAILED] = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.white, _1.colors.orange70),
    _a[ApplicationStatusBadge_component_1.ApplicationResultStatus.SCREENING_PASSED] = (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.white, _1.colors.blue70),
    _a[ApplicationStatusBadge_component_1.ApplicationResultStatus.INTERVIEW_FAILED] = (0, react_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.white, _1.colors.red70),
    _a[ApplicationStatusBadge_component_1.ApplicationResultStatus.INTERVIEW_TO_BE_DETERMINED] = (0, react_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.purple70, _1.colors.purple20),
    _a[ApplicationStatusBadge_component_1.ApplicationResultStatus.INTERVIEW_PASSED] = (0, react_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.white, _1.colors.green70),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.TO_BE_DETERMINED] = (0, react_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.gray60, _1.colors.gray20),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.NOT_APPLICABLE] = (0, react_1.css)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.white, _1.colors.gray60),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.INTERVIEW_CONFIRM_WAITING] = (0, react_1.css)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.blue70, _1.colors.blue20),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.INTERVIEW_CONFIRM_ACCEPTED] = (0, react_1.css)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.white, _1.colors.blue70),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.INTERVIEW_CONFIRM_REJECTED] = (0, react_1.css)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.red70, _1.colors.red20),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.FINAL_CONFIRM_WAITING] = (0, react_1.css)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.purple70, _1.colors.purple20),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.FINAL_CONFIRM_ACCEPTED] = (0, react_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.white, _1.colors.green70),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.FINAL_CONFIRM_REJECTED] = (0, react_1.css)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.white, _1.colors.red70),
    _a[ApplicationStatusBadge_component_1.ApplicationConfirmationStatus.TO_BE_DETERMINED] = (0, react_1.css)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    color: ", ";\n    background-color: ", ";\n  "], ["\n    color: ", ";\n    background-color: ", ";\n  "])), _1.colors.gray60, _1.colors.gray20),
    _a[types_1.EmailStatus.CREATED] = (0, react_1.css)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    color: ", ";\n    border: 0.1rem solid ", ";\n  "], ["\n    color: ", ";\n    border: 0.1rem solid ", ";\n  "])), _1.colors.blue70, _1.colors.blue70),
    _a[types_1.EmailStatus.FAIL] = (0, react_1.css)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    color: ", ";\n    border: 0.1rem solid ", ";\n  "], ["\n    color: ", ";\n    border: 0.1rem solid ", ";\n  "])), _1.colors.red70, _1.colors.red70),
    _a[types_1.EmailStatus.SUCCESS] = (0, react_1.css)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    color: ", ";\n    border: 0.1rem solid ", ";\n  "], ["\n    color: ", ";\n    border: 0.1rem solid ", ";\n  "])), _1.colors.blue70, _1.colors.blue70),
    _a);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
