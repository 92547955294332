"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    /**
     * @type {I18nDictionary}
     */
    messages: {
        ui: {
            blockTunes: {
                toggler: {
                    'Click to tune': '클릭해서 메뉴 열기 또는 드래그 해서 옮기기',
                    'or drag to move': '드래그해서 옮기기',
                },
            },
            inlineToolbar: {
                converter: {
                    'Convert to': '블록 변환',
                },
            },
            toolbar: {
                toolbox: {
                    Add: '블록 추가하기',
                },
            },
        },
        toolNames: {
            Text: '텍스트',
            Heading: '제목',
            List: '리스트',
            Warning: '콜아웃',
            Checklist: '체크리스트',
            Quote: '인용',
            Table: '표',
            Link: '링크',
            Marker: '마커',
            Bold: '굵게',
            Italic: '기울임',
            Underline: '밑줄',
        },
        tools: {
            table: {
                'With headings': '제목 추가',
                'Without headings': '제목 제거',
                'Add column to left': '왼쪽에 열 삽입',
                'Add column to right': '오른쪽에 열 삽입',
                'Delete column': '열 삭제',
                'Add row above': '위에 행 삽입',
                'Add row below': '아래에 행 삽입',
                'Delete row': '행 삭제',
                Heading: '제목',
            },
            header: {
                'Heading 1': '제목 1',
                'Heading 2': '제목 2',
                'Heading 3': '제목 3',
                'Heading 4': '제목 4',
            },
            list: {
                Unordered: '글머리 목록',
                Ordered: '번호 목록',
            },
        },
        blockTunes: {
            delete: {
                Delete: '삭제',
                'Click to delete': '확인',
            },
            moveUp: {
                'Move up': '위로 이동',
            },
            moveDown: {
                'Move down': '아래로 이동',
            },
        },
    },
};
