"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTypes = exports.EmailStatus = void 0;
exports.EmailStatus = {
    CREATED: '이메일 생성',
    SUCCESS: '발송성공',
    FAIL: '발송실패',
};
exports.EmailTypes = {
    SUBMIT: '제출',
    SCREENING_RESULT: '서류 결과 발표',
    SCREENING_DELAY: '서류 결과 발표 지연',
    INTERVIEW_RESULT: '면접 결과 발표',
};
