"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var hooks_1 = require("@/hooks");
var __1 = require("..");
var Blocker = function (_a) {
    var _b = _a.isBlocking, isBlocking = _b === void 0 ? true : _b;
    var _c = (0, hooks_1.usePrompt)(isBlocking), showPrompt = _c.showPrompt, confirmNavigation = _c.confirmNavigation, cancelNavigation = _c.cancelNavigation;
    if (!showPrompt) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(__1.AlertModalDialog, { heading: "\uC0AD\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?", paragraph: "\uC791\uC131 \uB610\uB294 \uC218\uC815\uD558\uC2E0 \uB370\uC774\uD130\uAC00 \uC0AD\uC81C\uB429\uB2C8\uB2E4.", cancelButtonLabel: "\uCDE8\uC18C", confirmButtonLabel: "\uB2EB\uAE30", handleClickCancelButton: cancelNavigation, handleClickConfirmButton: confirmNavigation }, void 0));
};
exports.default = Blocker;
