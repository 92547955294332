"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonShape = exports.ButtonSize = void 0;
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var Styled = __importStar(require("./Button.styled"));
exports.ButtonSize = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
};
exports.ButtonShape = {
    default: 'default',
    defaultLine: 'defaultLine',
    primary: 'primary',
    primaryLine: 'primaryLine',
    icon: 'icon',
    smallIcon: 'smallIcon',
};
var Button = function (_a, parentRef) {
    var children = _a.children, className = _a.className, _b = _a.$size, $size = _b === void 0 ? 'sm' : _b, _c = _a.shape, shape = _c === void 0 ? 'default' : _c, Icon = _a.Icon, label = _a.label, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, resetProps = __rest(_a, ["children", "className", "$size", "shape", "Icon", "label", "isLoading"]);
    var childRef = (0, react_2.useRef)(null);
    var showLabel = shape !== exports.ButtonShape.icon && !isLoading;
    (0, react_2.useImperativeHandle)(parentRef, function () {
        return {
            focus: function () {
                if (childRef.current) {
                    childRef.current.focus();
                }
            },
        };
    });
    return ((0, jsx_runtime_1.jsxs)(Styled.ButtonWrapper, __assign({ ref: childRef, type: "button", className: className, "$size": $size, shape: shape, disabled: isLoading }, resetProps, { children: [Icon && (0, jsx_runtime_1.jsx)(Icon, {}, void 0), showLabel && label, children, isLoading && (0, jsx_runtime_1.jsx)(Styled.Spinner, {}, void 0)] }), void 0));
};
exports.default = (0, react_2.forwardRef)(Button);
