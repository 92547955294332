"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("@emotion/react");
var react_2 = require("react");
var useWindowScroll = function () {
    var _a = __read((0, react_2.useState)({ x: 0, y: 0 }), 2), scrollPosition = _a[0], setScrollPosition = _a[1];
    var onScroll = (0, react_2.useCallback)(function () {
        setScrollPosition({ x: window.scrollX, y: window.scrollY });
    }, [setScrollPosition]);
    (0, react_2.useEffect)(function () {
        window.addEventListener('scroll', onScroll);
        return function () {
            window.removeEventListener('scroll', onScroll);
        };
    }, [onScroll]);
    return scrollPosition;
};
exports.default = useWindowScroll;
