"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zIndex = void 0;
exports.zIndex = {
    default: 0,
    sticky: 5,
    modal: 10,
    select: 10,
    dialog: 15,
    loading: 20,
    toast: 25,
};
