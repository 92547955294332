"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectorFamilyWithRefresher = exports.selectorWithRefresher = void 0;
var recoil_1 = require("recoil");
var selectorWithRefresher = function (_a) {
    var key = _a.key, callback = _a.get;
    var $requestId = (0, recoil_1.atom)({
        key: "".concat(key, "RequestId"),
        default: 0,
    });
    return (0, recoil_1.selector)({
        key: key,
        get: function (selectorOptions) {
            selectorOptions.get($requestId);
            return callback(selectorOptions);
        },
        set: function (_a) {
            var set = _a.set;
            set($requestId, function (id) { return id + 1; });
        },
    });
};
exports.selectorWithRefresher = selectorWithRefresher;
var selectorFamilyWithRefresher = function (_a) {
    var key = _a.key, callback = _a.get;
    var $requestId = (0, recoil_1.atom)({
        key: "".concat(key, "RequestId"),
        default: 0,
    });
    return (0, recoil_1.selectorFamily)({
        key: key,
        get: function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            return function (selectorOptions) {
                selectorOptions.get($requestId);
                return callback.apply(void 0, __spreadArray([], __read(params), false))(selectorOptions);
            };
        },
        set: function () {
            return function (_a) {
                var set = _a.set;
                set($requestId, function (id) { return id + 1; });
            };
        },
    });
};
exports.selectorFamilyWithRefresher = selectorFamilyWithRefresher;
