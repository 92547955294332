"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUrlParam = void 0;
var parseUrlParam = function (param) {
    if (param === null || param === undefined) {
        return 0;
    }
    return parseInt(param, 10);
};
exports.parseUrlParam = parseUrlParam;
