"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_router_dom_1 = require("react-router-dom");
var recoil_1 = require("recoil");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var store_1 = require("@/store");
var useMyTeam = function () {
    var _a = __read((0, react_router_dom_1.useSearchParams)(), 1), searchParams = _a[0];
    var teamName = searchParams.get('team');
    var myTeamName = (0, recoil_1.useRecoilValue)(store_1.$profile)[0];
    var isMyTeam = (0, react_2.useMemo)(function () {
        return !teamName ||
            teamName.toLowerCase() === myTeamName.toLowerCase() ||
            myTeamName === 'BRANDING' ||
            myTeamName === 'MASHUP';
    }, [myTeamName, teamName]);
    return {
        isMyTeam: isMyTeam,
    };
};
exports.default = useMyTeam;
