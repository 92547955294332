"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_hook_form_1 = require("react-hook-form");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var components_1 = require("@/components");
var hooks_1 = require("@/hooks");
var utils_1 = require("@/utils");
var DatePickerField = function (_a) {
    var name = _a.name, className = _a.className, control = _a.control, defaultDate = _a.defaultDate, onClick = _a.onClick, restProps = __rest(_a, ["name", "className", "control", "defaultDate", "onClick"]);
    var _b = __read((0, hooks_1.useToggleState)(false), 2), isDatePickerOpened = _b[0], toggleDatePickerOpened = _b[1];
    var _c = __read((0, react_2.useState)(null), 2), selectedDate = _c[0], setSelectedDate = _c[1];
    var containerRef = (0, react_2.useRef)(null);
    var formattedDate = (0, react_2.useMemo)(function () {
        if (!selectedDate) {
            return '';
        }
        return (0, utils_1.formatDate)(selectedDate.toDate(), 'YYYY.MM.DD');
    }, [selectedDate]);
    var handleClick = function (event) {
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
        if (!isDatePickerOpened) {
            toggleDatePickerOpened();
        }
    };
    var handleSelectDate = function (clickedDate) {
        setSelectedDate(clickedDate);
        toggleDatePickerOpened();
    };
    (0, react_2.useEffect)(function () {
        if (defaultDate) {
            setSelectedDate(defaultDate);
        }
    }, [defaultDate]);
    (0, hooks_1.useOnClickOutSide)(containerRef, function () {
        if (isDatePickerOpened)
            toggleDatePickerOpened();
    });
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field;
            return ((0, jsx_runtime_1.jsxs)("div", __assign({ ref: containerRef }, { children: [(0, jsx_runtime_1.jsx)(components_1.Input, __assign({}, restProps, { ref: field.ref, value: formattedDate, onClick: handleClick }), void 0), isDatePickerOpened && ((0, jsx_runtime_1.jsx)(components_1.DatePicker, { className: className, selectedDate: selectedDate, handleSelectDate: function (date) {
                            field.onChange(date);
                            handleSelectDate(date);
                        } }, void 0))] }), void 0));
        } }, void 0));
};
exports.default = DatePickerField;
