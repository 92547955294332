"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var Styled = __importStar(require("./AlertModalDialog.styled"));
var store_1 = require("@/store");
var ModalWrapper_component_1 = require("@/components/common/ModalWrapper/ModalWrapper.component");
var AlertModalDialog = function (_a) {
    var heading = _a.heading, _b = _a.paragraph, paragraph = _b === void 0 ? '' : _b, _c = _a.cancelButtonLabel, cancelButtonLabel = _c === void 0 ? '취소' : _c, _d = _a.confirmButtonLabel, confirmButtonLabel = _d === void 0 ? '확인' : _d, handleClickCancelButton = _a.handleClickCancelButton, handleClickConfirmButton = _a.handleClickConfirmButton;
    var setModal = (0, recoil_1.useSetRecoilState)((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog));
    var handleRemoveCurrentModal = function () {
        setModal({ key: store_1.ModalKey.alertModalDialog, isOpen: false });
    };
    var props = {
        footer: {
            cancelButton: {
                label: cancelButtonLabel,
                onClick: handleClickCancelButton || handleRemoveCurrentModal,
            },
            confirmButton: {
                label: confirmButtonLabel,
                onClick: handleClickConfirmButton || handleRemoveCurrentModal,
            },
            position: ModalWrapper_component_1.Position.center,
        },
        handleCloseModal: handleClickCancelButton || handleRemoveCurrentModal,
    };
    return ((0, jsx_runtime_1.jsx)(components_1.ModalWrapper, __assign({}, props, { children: (0, jsx_runtime_1.jsxs)(Styled.AlertModalDialogWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.AlertModalDialogHeader, { children: heading }, void 0), (0, jsx_runtime_1.jsx)(Styled.AlertModalDialogContent, { children: paragraph }, void 0)] }, void 0) }), void 0));
};
exports.default = AlertModalDialog;
