"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var react_hook_form_1 = require("react-hook-form");
var utils_1 = require("@/utils");
var components_1 = require("@/components");
var Styled = __importStar(require("./CreateHelperAdminMemberDialog.styled"));
var api = __importStar(require("@/api"));
var store_1 = require("@/store");
var Select_component_1 = require("@/components/common/Select/Select.component");
var hooks_1 = require("@/hooks");
var Toast_component_1 = require("@/components/common/Toast/Toast.component");
var CreateHelperAdminMemberDialog = function (_a) {
    var refreshList = _a.refreshList;
    var selectedPlatformRef = (0, react_2.useRef)(null);
    var handleAddToast = (0, hooks_1.useToast)().handleAddToast;
    var _b = (0, react_hook_form_1.useForm)(), setValue = _b.setValue, handleSubmit = _b.handleSubmit, register = _b.register;
    var _c = __read((0, react_2.useState)([]), 2), options = _c[0], setOptions = _c[1];
    var getTeams = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, newOptions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.getTeams()];
                case 1:
                    data = (_a.sent()).data;
                    newOptions = data.map(function (i) { return ({ value: i.name, label: i.name }); });
                    setOptions(newOptions);
                    setValue('platform', newOptions[0].value);
                    return [2 /*return*/];
            }
        });
    }); };
    (0, react_2.useEffect)(function () {
        getTeams();
    }, []);
    var handleRemoveCurrentModal = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set;
        return function () {
            set((0, store_1.$modalByStorage)(store_1.ModalKey.createHelperAdminMemberDialog), {
                key: store_1.ModalKey.createHelperAdminMemberDialog,
                isOpen: false,
            });
        };
    });
    var handleCreateMember = (0, recoil_1.useRecoilCallback)(function () {
        return function (_a) {
            var username = _a.username, platform = _a.platform, password = _a.password;
            (0, utils_1.request)({
                requestFunc: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, api.createAdminMember({
                                    username: username,
                                    platform: platform,
                                    password: password,
                                })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); },
                onSuccess: function () {
                    handleAddToast({
                        type: Toast_component_1.ToastType.success,
                        message: '성공적으로 생성되었습니다.',
                    });
                    handleRemoveCurrentModal();
                    refreshList === null || refreshList === void 0 ? void 0 : refreshList();
                },
                errorHandler: handleAddToast,
            });
        };
    });
    var props = {
        heading: '헬퍼 생성',
        footer: {
            cancelButton: {
                label: '취소',
            },
            confirmButton: {
                label: '생성',
                onClick: handleSubmit(handleCreateMember),
                type: 'submit',
            },
        },
        handleCloseModal: handleRemoveCurrentModal,
        isContentScroll: false,
    };
    var handleChangePlatformSelect = function (option) {
        setValue('platform', option.value);
    };
    return ((0, jsx_runtime_1.jsx)(components_1.ModalWrapper, __assign({}, props, { children: (0, jsx_runtime_1.jsxs)(Styled.ChangeResultModalContainer, { children: [(0, jsx_runtime_1.jsx)(components_1.TitleWithContent, __assign({ title: "\uD50C\uB7AB\uD3FC", isActive: true }, { children: (0, jsx_runtime_1.jsx)(components_1.Select, { size: Select_component_1.SelectSize.md, options: options, isFullWidth: true, ref: selectedPlatformRef, onChangeOption: handleChangePlatformSelect, defaultValue: options[0] }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(components_1.InputField, __assign({ required: true, "$size": "md", label: "\uC544\uC774\uB514", placeholder: "\uB0B4\uC6A9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694" }, register('username', { required: true })), void 0), (0, jsx_runtime_1.jsx)(components_1.InputField, __assign({ required: true, "$size": "md", label: "\uBE44\uBC00\uBC88\uD638", placeholder: "\uB0B4\uC6A9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694" }, register('password', { required: true })), void 0)] }, void 0) }), void 0));
};
exports.default = CreateHelperAdminMemberDialog;
