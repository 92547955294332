"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var react_router_dom_1 = require("react-router-dom");
var components_1 = require("@/components");
var Styled = __importStar(require("./Layout.styled"));
var constants_1 = require("@/constants");
var Generation_1 = require("@/components/Generation");
var Layout = function () {
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var isBackgroundGray = (0, react_2.useMemo)(function () {
        return [
            'login',
            'application/',
            'application-form/',
            'activity-score/',
            'schedule/',
            'recruit/',
        ].some(function (each) { return pathname.includes(each); });
    }, [pathname]);
    var isListPage = (0, react_2.useMemo)(function () {
        return [
            constants_1.PATH.APPLICATION,
            constants_1.PATH.APPLICATION_FORM,
            constants_1.PATH.ACTIVITY_SCORE,
            constants_1.PATH.ACTIVITY_SCORE,
            constants_1.PATH.SCHEDULE,
        ].some(function (each) { return pathname === each; });
    }, [pathname]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Header, {}, void 0), (0, jsx_runtime_1.jsxs)(Styled.Wrapper, { children: [(0, jsx_runtime_1.jsx)(components_1.LNB, {}, void 0), (0, jsx_runtime_1.jsx)(Styled.Main, { children: (0, jsx_runtime_1.jsx)(Styled.Container, __assign({ isBackgroundGray: isBackgroundGray }, { children: (0, jsx_runtime_1.jsxs)("section", { children: [isListPage && ((0, jsx_runtime_1.jsx)(Styled.SelectWrapper, { children: (0, jsx_runtime_1.jsx)(Generation_1.GenerationSelect, {}, void 0) }, void 0)), (0, jsx_runtime_1.jsx)(react_router_dom_1.Outlet, {}, void 0)] }, void 0) }), void 0) }, void 0)] }, void 0)] }, void 0));
};
exports.default = Layout;
