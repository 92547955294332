"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigation = void 0;
var react_1 = require("@emotion/react");
var Navigation_component_1 = require("@/components/common/Navigation/Navigation.component");
exports.navigation = {
    size: (_a = {},
        _a[Navigation_component_1.NavigationSize.sm] = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      width: 8rem;\n      height: 4rem;\n\n      &:not(:first-of-type) {\n        margin-left: 0.8rem;\n      }\n    "], ["\n      width: 8rem;\n      height: 4rem;\n\n      &:not(:first-of-type) {\n        margin-left: 0.8rem;\n      }\n    "]))),
        _a[Navigation_component_1.NavigationSize.md] = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      width: 100%;\n      height: 5rem;\n    "], ["\n      width: 100%;\n      height: 5rem;\n    "]))),
        _a),
};
var templateObject_1, templateObject_2;
