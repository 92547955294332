"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSameObject = exports.getOwnValueByKey = void 0;
var type_1 = require("./type");
var getOwnValueByKey = function (object, path) {
    if (!path) {
        return undefined;
    }
    var keys = path.split('.');
    return keys.reduce(function (pivot, key) {
        return pivot ? pivot[key] : undefined;
    }, object);
};
exports.getOwnValueByKey = getOwnValueByKey;
function isSameObject(one, another) {
    var keysOfOne = Object.keys(one);
    var keysOfAnother = Object.keys(another);
    return (keysOfOne.length === keysOfAnother.length &&
        keysOfOne.every(function (key) {
            if ((0, type_1.isObject)(one[key]) ||
                (0, type_1.isObject)(another[key]) ||
                (0, type_1.isArray)(one[key]) ||
                (0, type_1.isArray)(another[key])) {
                return isSameObject(one[key], another[key]);
            }
            return one[key] === another[key];
        }));
}
exports.isSameObject = isSameObject;
