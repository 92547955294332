"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var react_router_dom_1 = require("react-router-dom");
var components_1 = require("@/components");
var Button_component_1 = require("@/components/common/Button/Button.component");
var Styled = __importStar(require("./SearchOptionBar.styled"));
var Select_component_1 = require("../Select/Select.component");
var SearchOptionBar = function (_a) {
    var placeholder = _a.placeholder, filters = _a.filters;
    var _b = __read((0, react_router_dom_1.useSearchParams)(), 2), searchParams = _b[0], setSearchParams = _b[1];
    var ref = (0, react_2.useRef)(null);
    var searchWord = searchParams.get('searchWord') || '';
    var _c = __read((0, react_2.useState)({ value: '' }), 2), searchKeyword = _c[0], setSearchKeyword = _c[1];
    var handleChangeFilter = function (selectedOption, filter) {
        var key = filter.key, defaultOption = filter.defaultOption;
        if (!!defaultOption && selectedOption.value === defaultOption.value) {
            searchParams.delete(key);
            setSearchParams(searchParams);
            return;
        }
        searchParams.set(key, selectedOption.value);
        setSearchParams(searchParams);
    };
    var handleSearch = function (e) {
        var value = e.target.searchWord.value;
        e.preventDefault();
        if (!value) {
            searchParams.delete('searchWord');
            setSearchParams(searchParams);
            return;
        }
        searchParams.set('searchWord', value);
        setSearchParams(searchParams);
    };
    var defaultValue = function (filter) {
        var _a;
        var searchParam = searchParams.get(filter.key);
        if (!searchParam) {
            return (_a = filter.options) === null || _a === void 0 ? void 0 : _a[0];
        }
        return filter.options.find(function (option) { return option.value === searchParam; });
    };
    (0, react_2.useLayoutEffect)(function () {
        if (ref.current) {
            ref.current.value = searchKeyword.value;
        }
    }, [searchKeyword]);
    (0, react_2.useEffect)(function () {
        setSearchKeyword({ value: searchWord });
    }, [searchWord]);
    return ((0, jsx_runtime_1.jsxs)(Styled.BarContainer, __assign({ onSubmit: handleSearch }, { children: [(0, jsx_runtime_1.jsx)(Styled.SelectContainer, { children: filters === null || filters === void 0 ? void 0 : filters.map(function (filter) { return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("div", { children: filter.title }, void 0), (0, jsx_runtime_1.jsx)(components_1.Select, { size: Select_component_1.SelectSize.xs, options: filter.options, onChangeOption: function (option) { return handleChangeFilter(option, filter); }, defaultValue: defaultValue(filter) }, void 0)] }, void 0)); }) }, void 0), (0, jsx_runtime_1.jsx)("div", {}, void 0), (0, jsx_runtime_1.jsxs)(Styled.SearchInputContainer, { children: [(0, jsx_runtime_1.jsx)(components_1.Input, { ref: ref, name: "searchWord", "$size": "xs", placeholder: placeholder }, void 0), (0, jsx_runtime_1.jsx)(Styled.SearchButton, __assign({ type: "submit", "$size": Button_component_1.ButtonSize.xs, shape: Button_component_1.ButtonShape.default }, { children: "\uAC80\uC0C9" }), void 0)] }, void 0)] }), void 0));
};
exports.default = SearchOptionBar;
