"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationResultStatus = exports.ApplicationConfirmationStatus = void 0;
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var Styled = __importStar(require("./ApplicationStatusBadge.styled"));
exports.ApplicationConfirmationStatus = {
    TO_BE_DETERMINED: '미검토',
    NOT_APPLICABLE: '해당 없음',
    INTERVIEW_CONFIRM_WAITING: '면접 확인 대기',
    INTERVIEW_CONFIRM_ACCEPTED: '면접 수락',
    INTERVIEW_CONFIRM_REJECTED: '면접 거절',
    FINAL_CONFIRM_WAITING: '최종 확인 대기',
    FINAL_CONFIRM_ACCEPTED: '최종 수락',
    FINAL_CONFIRM_REJECTED: '최종 거절',
};
exports.ApplicationResultStatus = {
    NOT_RATED: '미검토',
    SCREENING_TO_BE_DETERMINED: '서류 보류',
    SCREENING_FAILED: '서류 불합격',
    SCREENING_PASSED: '서류 합격',
    INTERVIEW_TO_BE_DETERMINED: '최종 보류',
    INTERVIEW_FAILED: '최종 불합격',
    INTERVIEW_PASSED: '최종 합격',
};
var ApplicationStatusBadge = function (_a) {
    var text = _a.text, resetProps = __rest(_a, ["text"]);
    return ((0, jsx_runtime_1.jsx)(Styled.ApplicationStatusBadgeWrapper, __assign({ text: text }, resetProps, { children: text }), void 0));
};
exports.default = ApplicationStatusBadge;
