"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var react_router_dom_1 = require("react-router-dom");
var common_1 = require("@/components/common");
var Select_component_1 = require("@/components/common/Select/Select.component");
var store_1 = require("@/store");
var GenerationSelect = function () {
    var generations = (0, recoil_1.useRecoilValue)(store_1.$generations);
    var _a = __read((0, recoil_1.useRecoilState)(store_1.$generationNumber), 2), generationNumber = _a[0], setGenerationNumber = _a[1];
    var _b = __read((0, react_router_dom_1.useSearchParams)(), 2), searchParams = _b[0], setSearchParams = _b[1];
    var options = generations.map(function (generation) { return ({
        label: "".concat(generation.generationNumber, "\uAE30"),
        value: "".concat(generation.generationNumber),
    }); });
    var handleChangeGeneration = function (selectedOption) {
        setGenerationNumber(parseInt(selectedOption.value, 10));
        searchParams.delete('page');
        setSearchParams(searchParams);
    };
    (0, react_2.useEffect)(function () {
        if (generations.length && !generationNumber) {
            setGenerationNumber(generations[0].generationNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generationNumber, generations]);
    return ((0, jsx_runtime_1.jsx)(common_1.Select, { currentValue: {
            label: "".concat(generationNumber, "\uAE30"),
            value: "".concat(generationNumber),
        }, size: Select_component_1.SelectSize.xs, options: options, onChangeOption: function (option) { return handleChangeGeneration(option); } }, void 0));
};
exports.default = GenerationSelect;
