"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$modalByStorage = exports.$modal = exports.ModalKey = void 0;
var recoil_1 = require("recoil");
var recoil_persist_1 = require("recoil-persist");
exports.ModalKey = {
    alertModalDialog: 'alertModalDialog',
    emailSendModalDialog: 'emailSendModalDialog',
    changeResultModalDialog: 'changeResultModalDialog',
    emailSendDetailInfoModalDialog: 'emailSendDetailInfoModalDialog',
    emailSendDetailListModalDialog: 'emailSendDetailListModalDialog',
    applyActivityScoreModalDialog: 'applyActivityScoreModalDialog',
    activityScoreModalDialog: 'activityScoreModalDialog',
    createQRCodeModalDialog: 'createQRCodeModalDialog',
    displayQRCodeModalDialog: 'displayQRCodeModalDialog',
    createHelperAdminMemberDialog: 'createHelperAdminMemberDialog',
};
var persistAtom = (0, recoil_persist_1.recoilPersist)({ key: 'modal', storage: localStorage }).persistAtom;
exports.$modal = (0, recoil_1.atomFamily)({
    key: 'modal',
    default: function (key) {
        return {
            key: key,
            isOpen: false,
        };
    },
    effects_UNSTABLE: [persistAtom],
});
exports.$modalByStorage = (0, recoil_1.selectorFamily)({
    key: 'modalByStorage',
    get: function (key) {
        return function (_a) {
            var get = _a.get;
            return get((0, exports.$modal)(key));
        };
    },
    set: function (key) {
        return function (_a, params) {
            var set = _a.set;
            set((0, exports.$modal)(key), params);
        };
    },
});
