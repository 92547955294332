"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_hook_form_1 = require("react-hook-form");
var recoil_1 = require("recoil");
var react_router_dom_1 = require("react-router-dom");
var components_1 = require("@/components");
var Styled = __importStar(require("./CreateSchedule.styled"));
var hooks_1 = require("@/hooks");
var constants_1 = require("@/constants");
var Schedule_1 = require("@/components/Schedule");
var utils_1 = require("@/utils");
var api = __importStar(require("@/api"));
var styles_1 = require("@/styles");
var store_1 = require("@/store");
var DEFAULT_SESSIONS = [
    {
        contentsCreateRequests: [{}],
    },
];
var CreateSchedule = function () {
    var handleGoBack = (0, hooks_1.useHistory)().handleGoBack;
    var methods = (0, react_hook_form_1.useForm)({ defaultValues: { sessions: DEFAULT_SESSIONS } });
    var handleSubmit = methods.handleSubmit, formState = methods.formState;
    var handleAddToast = (0, hooks_1.useToast)().handleAddToast;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var refreshSelectorFamilyByKey = (0, hooks_1.useRefreshSelectorFamilyByKey)();
    var isDirty = formState.isDirty, isSubmitting = formState.isSubmitting, isSubmitSuccessful = formState.isSubmitSuccessful;
    var handleSubmitForm = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set;
        return function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var createScheduleRequest;
            return __generator(this, function (_a) {
                createScheduleRequest = (0, utils_1.parseFormValuesToScheduleRequest)(data);
                if (createScheduleRequest.eventsCreateRequests.length === 0) {
                    handleAddToast({
                        type: 'error',
                        message: '최소 한가지의 세션을 작성해야 합니다.',
                    });
                    return [2 /*return*/];
                }
                if (createScheduleRequest.eventsCreateRequests.some(function (_a) {
                    var contentsCreateRequests = _a.contentsCreateRequests;
                    return contentsCreateRequests.length === 0;
                })) {
                    handleAddToast({
                        type: 'error',
                        message: '콘텐츠가 작성되지 않은 세션이 있습니다.',
                    });
                    return [2 /*return*/];
                }
                set((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog), {
                    key: store_1.ModalKey.alertModalDialog,
                    isOpen: true,
                    props: {
                        heading: '저장하시겠습니까?',
                        paragraph: '스케줄 내역에서 확인하실 수 있습니다.',
                        confirmButtonLabel: '저장',
                        handleClickConfirmButton: function () {
                            (0, utils_1.request)({
                                requestFunc: function () {
                                    return api.createSchedule(createScheduleRequest);
                                },
                                errorHandler: handleAddToast,
                                onSuccess: function (response) {
                                    var scheduleId = response.data.scheduleId;
                                    handleAddToast({
                                        type: styles_1.ToastType.success,
                                        message: '성공적으로 스케줄을 추가했습니다.',
                                    });
                                    refreshSelectorFamilyByKey('schedules');
                                    navigate((0, constants_1.getScheduleDetailPage)(scheduleId));
                                },
                                onCompleted: function () {
                                    set((0, store_1.$modalByStorage)(store_1.ModalKey.alertModalDialog), {
                                        key: store_1.ModalKey.alertModalDialog,
                                        isOpen: false,
                                    });
                                },
                            });
                        },
                    },
                });
                return [2 /*return*/];
            });
        }); };
    });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, __assign({}, methods, { children: (0, jsx_runtime_1.jsx)(Styled.CreateSchedulePage, { children: (0, jsx_runtime_1.jsxs)("form", __assign({ onSubmit: handleSubmit(handleSubmitForm) }, { children: [(0, jsx_runtime_1.jsx)(components_1.BackButton, { label: "\uBAA9\uB85D \uB3CC\uC544\uAC00\uAE30", onClick: function () { return handleGoBack(constants_1.PATH.SCHEDULE); } }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Header, { children: [(0, jsx_runtime_1.jsx)(Styled.Headline, { children: "\uC2A4\uCF00\uC904" }, void 0), (0, jsx_runtime_1.jsxs)(Styled.ButtonWrapper, { children: [(0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": "sm", type: "button", shape: "defaultLine", isLoading: isSubmitting, onClick: function () { return navigate(-1); } }, { children: "\uCDE8\uC18C" }), void 0), (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": "sm", shape: "primary", type: "submit", isLoading: isSubmitting }, { children: "\uC800\uC7A5" }), void 0)] }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(Schedule_1.ScheduleTemplate, {}, void 0)] }), void 0) }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(components_1.Blocker, { isBlocking: isDirty && !isSubmitSuccessful }, void 0)] }, void 0));
};
exports.default = CreateSchedule;
