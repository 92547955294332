"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var utils_1 = require("@/utils");
var Styled = __importStar(require("./ContentListItem.styled"));
var ContentListItem = function (_a) {
    var index = _a.index, title = _a.title, content = _a.content, startedAt = _a.startedAt;
    return ((0, jsx_runtime_1.jsxs)(Styled.ContentListItem, { children: [(0, jsx_runtime_1.jsxs)(Styled.Row, { children: [(0, jsx_runtime_1.jsx)(Styled.IndexBadge, { children: (0, utils_1.getReadableIndex)(index) }, void 0), (0, jsx_runtime_1.jsx)(Styled.Title, { children: title }, void 0), (0, jsx_runtime_1.jsx)(Styled.StartedAt, { children: (0, utils_1.formatDate)(startedAt, 'A hh:mm') }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(Styled.Content, { children: (0, utils_1.parsePlaceholderWhenEmpty)(content) }, void 0)] }, void 0));
};
exports.default = ContentListItem;
