"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var Styled = __importStar(require("./PersonalInfoCard.styled"));
var PersonalInfoCard = function (_a) {
    var name = _a.name, platform = _a.platform, identification = _a.identification, generationNumber = _a.generationNumber;
    return ((0, jsx_runtime_1.jsxs)(Styled.Wrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.Headline, { children: "\uC778\uC801 \uC815\uBCF4" }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Row, { children: [(0, jsx_runtime_1.jsxs)(Styled.Col, { children: [(0, jsx_runtime_1.jsx)(Styled.Label, { children: "\uC774\uB984" }, void 0), (0, jsx_runtime_1.jsx)(Styled.Content, { children: name }, void 0)] }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Col, { children: [(0, jsx_runtime_1.jsx)(Styled.Label, { children: "\uD50C\uB7AB\uD3FC" }, void 0), (0, jsx_runtime_1.jsx)(Styled.Content, { children: platform }, void 0)] }, void 0)] }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Row, { children: [(0, jsx_runtime_1.jsxs)(Styled.Col, { children: [(0, jsx_runtime_1.jsx)(Styled.Label, { children: "\uC544\uC774\uB514" }, void 0), (0, jsx_runtime_1.jsx)(Styled.Content, { children: identification }, void 0)] }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Col, { children: [(0, jsx_runtime_1.jsx)(Styled.Label, { children: "\uAE30\uC218" }, void 0), (0, jsx_runtime_1.jsxs)(Styled.Content, { children: [generationNumber, "\uAE30"] }, void 0)] }, void 0)] }, void 0)] }, void 0));
};
exports.default = PersonalInfoCard;
