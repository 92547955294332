"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var components_1 = require("@/components");
var store_1 = require("@/store");
var types_1 = require("@/types");
var utils_1 = require("@/utils");
var hooks_1 = require("@/hooks");
var constants_1 = require("@/constants");
var Styled = __importStar(require("./EmailSendDetailInfoModalDialog.styled"));
var Button_component_1 = require("@/components/common/Button/Button.component");
var EMAIL_STATUS = {
    CREATED: '생성',
    SUCCESS: '성공',
    FAIL: '실패',
};
var columns = [
    {
        title: '이름',
        accessor: 'recipientName',
        widthRatio: '20%',
    },
    {
        title: '이메일',
        accessor: 'recipientEmail',
        widthRatio: '35%',
    },
    {
        title: '지원플랫폼',
        accessor: 'team',
        widthRatio: '25%',
    },
    {
        title: '발송여부',
        accessor: 'status',
        widthRatio: '20%',
        renderCustomCell: function (_a) {
            var cellValue = _a.cellValue;
            return ((0, jsx_runtime_1.jsx)(Styled.StatusWrapper, __assign({ status: cellValue }, { children: EMAIL_STATUS[cellValue] }), void 0));
        },
    },
];
var EmailSendDetailInfoModalDialog = function (_a) {
    var email = _a.email;
    var _b = __read(email.sender.split('_'), 2), team = _b[0], role = _b[1];
    var _c = (0, hooks_1.usePagination)({
        totalCount: email.emailRequests.length,
        pagingSize: 10,
        pageButtonsSize: 7,
        usingSearchParams: false,
    }), pageOptions = _c.pageOptions, handleChangePage = _c.handleChangePage;
    var _d = __read((0, react_2.useState)(email.emailRequests), 2), tableRows = _d[0], setTableRows = _d[1];
    var pagedRows = (0, react_2.useMemo)(function () {
        var startIndex = 10 * (pageOptions.currentPage - 1);
        var endIndex = startIndex + 10;
        return tableRows.slice(startIndex, endIndex);
    }, [tableRows, pageOptions]);
    var _e = __read((0, react_2.useState)([
        { accessor: 'recipientName', type: constants_1.SORT_TYPE.DEFAULT },
        { accessor: 'team', type: constants_1.SORT_TYPE.DEFAULT },
        { accessor: 'status', type: constants_1.SORT_TYPE.DEFAULT },
    ]), 2), sortTypes = _e[0], setSortTypes = _e[1];
    var handleRemoveCurrentModal = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set;
        return function () {
            set((0, store_1.$modalByStorage)(store_1.ModalKey.emailSendDetailInfoModalDialog), {
                key: store_1.ModalKey.emailSendDetailInfoModalDialog,
                isOpen: false,
            });
        };
    });
    var failedEmailRequests = (0, react_2.useMemo)(function () { return email.emailRequests.filter(function (_a) {
        var status = _a.status;
        return EMAIL_STATUS[status] === EMAIL_STATUS.FAIL;
    }); }, [email]);
    var handleEmailModal = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set;
        return function () {
            set((0, store_1.$modalByStorage)(store_1.ModalKey.emailSendModalDialog), {
                key: store_1.ModalKey.emailSendModalDialog,
                props: {
                    selectedApplications: [],
                    isSendFailed: true,
                    failedEmailRequests: failedEmailRequests,
                },
                isOpen: true,
            });
        };
    });
    var props = {
        heading: '이메일 발송 상세내역',
        footer: {
            cancelButton: {
                label: '닫기',
            },
        },
        handleCloseModal: handleRemoveCurrentModal,
        isContentScroll: false,
        closeOnClickOverlay: false,
    };
    return ((0, jsx_runtime_1.jsx)(components_1.ModalWrapper, __assign({}, props, { children: (0, jsx_runtime_1.jsxs)(Styled.Wrapper, { children: [(0, jsx_runtime_1.jsxs)(Styled.DetailWrapper, { children: [(0, jsx_runtime_1.jsx)(components_1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uBA54\uBAA8" }, { children: email.name }), void 0), (0, jsx_runtime_1.jsx)(components_1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uC774\uBA54\uC77C" }, { children: "recruit.mashup@gmail.com" }), void 0), (0, jsx_runtime_1.jsx)(components_1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uC790" }, { children: (0, jsx_runtime_1.jsx)(Styled.CustomUserProfile, { children: (0, jsx_runtime_1.jsx)(components_1.UserProfile, { team: team, role: role, showBackground: false, removePadding: true }, void 0) }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(components_1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uC77C\uC2DC" }, { children: email.sendAt ? (0, utils_1.formatDate)(email.sendAt, 'YYYY년 M월 D일 A h시 m분') : '-' }), void 0), (0, jsx_runtime_1.jsx)(components_1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uC5EC\uBD80(\uC131\uACF5/\uC2E4\uD328/\uC804\uCCB4)" }, { children: (0, jsx_runtime_1.jsxs)(Styled.SendingStatus, { children: [(0, jsx_runtime_1.jsx)("span", { children: email.successCount }, void 0), "/", (0, jsx_runtime_1.jsx)("span", { children: email.failureCount }, void 0), "/", (0, jsx_runtime_1.jsx)("span", { children: email.totalCount }, void 0)] }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(Styled.ContentWrapper, { children: (0, jsx_runtime_1.jsx)(components_1.TitleWithContent, __assign({ title: "\uBC1C\uC1A1\uC720\uD615" }, { children: types_1.EmailTypes[email.type] }), void 0) }, void 0)] }, void 0), (0, jsx_runtime_1.jsx)(Styled.TableWrapper, { children: (0, jsx_runtime_1.jsx)(components_1.Table, { prefix: "email", columns: columns, rows: pagedRows, supportBar: {
                            totalSummaryText: ' ',
                            buttons: [
                                (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ "$size": Button_component_1.ButtonSize.xs, shape: Button_component_1.ButtonShape.primary, onClick: function () { return handleEmailModal(); } }, { children: "\uC2E4\uD328\uC778\uC6D0 \uC7AC\uBC1C\uC1A1" }), void 0),
                            ],
                        }, sortOptions: {
                            sortTypes: sortTypes,
                            disableMultiSort: true,
                            handleSortColumn: function (_sortTypes) {
                                var pivotColumn = _sortTypes.find(function (sortType) { return sortType.type !== constants_1.SORT_TYPE.DEFAULT; });
                                if (!pivotColumn) {
                                    setTableRows(email.emailRequests);
                                }
                                else {
                                    setTableRows(__spreadArray([], __read(tableRows), false).sort(function (one, another) {
                                        return (0, utils_1.sortString)(pivotColumn.type, (0, utils_1.getOwnValueByKey)(one, pivotColumn.accessor), (0, utils_1.getOwnValueByKey)(another, pivotColumn.accessor));
                                    }));
                                }
                                setSortTypes(_sortTypes);
                            },
                        }, pagination: (0, jsx_runtime_1.jsx)(components_1.Pagination, { pageOptions: pageOptions, handleChangePage: handleChangePage }, void 0) }, void 0) }, void 0)] }, void 0) }), void 0));
};
exports.default = EmailSendDetailInfoModalDialog;
