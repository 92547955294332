"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$generationNumber = exports.$generations = void 0;
var recoil_1 = require("recoil");
var recoil_persist_1 = require("recoil-persist");
var persistAtom = (0, recoil_persist_1.recoilPersist)({ key: 'generationNumber', storage: localStorage }).persistAtom;
exports.$generations = (0, recoil_1.atom)({
    key: 'generations',
    default: [],
});
exports.$generationNumber = (0, recoil_1.atom)({
    key: 'generationNumber',
    default: undefined,
    effects_UNSTABLE: [persistAtom],
});
