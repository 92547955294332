"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var recoil_1 = require("recoil");
var react_router_dom_1 = require("react-router-dom");
var components_1 = require("@/components");
var hooks_1 = require("@/hooks");
var store_1 = require("@/store");
var constants_1 = require("@/constants");
var utils_1 = require("@/utils");
var api = __importStar(require("@/api"));
var Styled = __importStar(require("./EmailSendingList.styled"));
var types_1 = require("@/types");
var changeParamForBackend = function (sortParam) {
    switch (sortParam) {
        case 'type':
            return 'templateName';
        case 'sendAt':
            return 'createdAt';
        default:
            return sortParam;
    }
};
var EmailSendingList = function () {
    var _a;
    var _b = __read((0, react_router_dom_1.useSearchParams)(), 1), searchParams = _b[0];
    var page = searchParams.get('page') || '1';
    var size = searchParams.get('size') || '20';
    var searchWord = searchParams.get('searchWord') || '';
    var _c = __read((0, react_2.useState)([
        { accessor: 'type', type: constants_1.SORT_TYPE.DEFAULT },
        { accessor: 'sendAt', type: constants_1.SORT_TYPE.DEFAULT },
    ]), 2), sortTypes = _c[0], setSortTypes = _c[1];
    var sortParam = (0, react_2.useMemo)(function () {
        var matched = sortTypes.find(function (sortType) { return sortType.type !== constants_1.SORT_TYPE.DEFAULT; });
        if (!matched)
            return '';
        var accessor = matched.accessor, type = matched.type;
        return "".concat(changeParamForBackend(accessor), ",").concat(type);
    }, [sortTypes]);
    var emailSendingListParams = (0, react_2.useMemo)(function () { return ({
        page: parseInt(page, 10) - 1,
        size: parseInt(size, 10),
        searchWord: searchWord,
        sort: sortParam,
    }); }, [page, size, searchWord, sortParam]);
    var _d = __read((0, recoil_1.useRecoilStateLoadable)((0, store_1.$emailSendingList)(emailSendingListParams)), 1), _e = _d[0], state = _e.state, tableRows = _e.contents;
    var handleEmailModal = (0, recoil_1.useRecoilCallback)(function (_a) {
        var set = _a.set;
        return function (email) {
            set((0, store_1.$modalByStorage)(store_1.ModalKey.emailSendDetailInfoModalDialog), {
                key: store_1.ModalKey.emailSendDetailInfoModalDialog,
                props: { email: email },
                isOpen: true,
            });
        };
    });
    var columns = (0, react_2.useMemo)(function () { return [
        {
            title: '발송메모',
            accessor: 'name',
            idAccessor: 'emailNotificationId',
            widthRatio: '35%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue, id = _a.id;
                return ((0, jsx_runtime_1.jsx)(Styled.TitleButton, __assign({ onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var email;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, api.getEmailById({ notificationId: id })];
                                case 1:
                                    email = (_a.sent()).data;
                                    handleEmailModal(email);
                                    return [2 /*return*/];
                            }
                        });
                    }); } }, { children: cellValue }), void 0));
            },
        },
        {
            title: '발송유형',
            accessor: 'type',
            widthRatio: '15%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue;
                return types_1.EmailTypes[cellValue];
            },
        },
        {
            title: '발송자',
            accessor: 'sender',
            widthRatio: '15%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue;
                var _b = __read(cellValue.split('_'), 2), team = _b[0], role = _b[1];
                return ((0, jsx_runtime_1.jsx)(Styled.CustomUserProfile, { children: (0, jsx_runtime_1.jsx)(components_1.UserProfile, { team: team, role: role, showBackground: false }, void 0) }, void 0));
            },
        },
        {
            title: '발송일시',
            accessor: 'sendAt',
            widthRatio: '20%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue;
                return (0, utils_1.formatDate)(cellValue, 'YYYY년 M월 D일 A h시 m분');
            },
        },
        {
            title: '발송여부\n(성공/실패/전체)',
            accessor: ['successCount', 'failureCount', 'totalCount'],
            widthRatio: '10%',
            renderCustomCell: function (_a) {
                var cellValue = _a.cellValue;
                var _b = __read(cellValue, 3), successCount = _b[0], failureCount = _b[1], totalCount = _b[2];
                return ((0, jsx_runtime_1.jsxs)(Styled.SendingStatus, { children: [(0, jsx_runtime_1.jsx)("span", { children: successCount }, void 0), "/", (0, jsx_runtime_1.jsx)("span", { children: failureCount }, void 0), "/", (0, jsx_runtime_1.jsx)("span", { children: totalCount }, void 0)] }, void 0));
            },
        },
    ]; }, [handleEmailModal]);
    var _f = __read((0, react_2.useState)(0), 2), totalCount = _f[0], setTotalCount = _f[1];
    var isLoading = state === 'loading';
    var _g = __read((0, react_2.useState)(tableRows.data || []), 2), loadedTableRows = _g[0], setLoadedTableRows = _g[1];
    var _h = (0, hooks_1.usePagination)({
        totalCount: (_a = tableRows.page) === null || _a === void 0 ? void 0 : _a.totalCount,
    }), pageOptions = _h.pageOptions, handleChangePage = _h.handleChangePage, handleChangeSize = _h.handleChangeSize;
    var _j = (0, hooks_1.useDirty)(1), makeDirty = _j.makeDirty, isDirty = _j.isDirty;
    (0, react_2.useEffect)(function () {
        if (!isLoading) {
            setLoadedTableRows(tableRows.data);
            setTotalCount(tableRows.page.totalCount);
            makeDirty();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, tableRows]);
    (0, react_2.useLayoutEffect)(function () {
        if (isDirty && !isLoading) {
            window.scrollTo({ top: 167, left: 0, behavior: 'smooth' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedTableRows]);
    return ((0, jsx_runtime_1.jsxs)(Styled.PageWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.Heading, { children: "\uC774\uBA54\uC77C \uBC1C\uC1A1 \uB0B4\uC5ED" }, void 0), (0, jsx_runtime_1.jsx)(Styled.StickyContainer, { children: (0, jsx_runtime_1.jsx)(components_1.SearchOptionBar, { placeholder: "\uBC1C\uC1A1\uC720\uD615, \uBC1C\uC1A1\uC790, \uBC1C\uC1A1\uBA54\uBAA8 \uAC80\uC0C9" }, void 0) }, void 0), (0, jsx_runtime_1.jsx)(components_1.Table, { prefix: "email", topStickyHeight: 9.2, columns: columns, rows: loadedTableRows, isLoading: isLoading, supportBar: {
                    totalCount: totalCount,
                    totalSummaryText: '총 발송내역',
                }, sortOptions: {
                    sortTypes: sortTypes,
                    disableMultiSort: true,
                    handleSortColumn: function (_sortTypes) {
                        setSortTypes(_sortTypes);
                    },
                }, pagination: (0, jsx_runtime_1.jsx)(components_1.Pagination, { pageOptions: pageOptions, selectableSize: {
                        selectBoxPosition: loadedTableRows.length > 3 ? 'top' : 'bottom',
                        handleChangeSize: handleChangeSize,
                    }, handleChangePage: handleChangePage }, void 0) }, void 0), (0, jsx_runtime_1.jsx)(components_1.BottomCTA, __assign({ boundaries: {
                    visibility: { topHeight: 179, bottomHeight: 20 },
                    noAnimation: { bottomHeight: 20 },
                } }, { children: (0, jsx_runtime_1.jsx)(components_1.Pagination, { pageOptions: pageOptions, selectableSize: {
                        selectBoxPosition: 'top',
                        handleChangeSize: handleChangeSize,
                    }, handleChangePage: handleChangePage }, void 0) }), void 0)] }, void 0));
};
exports.default = EmailSendingList;
