"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$isMaster = exports.$profile = exports.$isAuthorized = exports.$me = void 0;
var recoil_1 = require("recoil");
var recoil_2 = require("./recoil");
var ActivityScore_1 = require("@/components/ActivityScore");
exports.$me = (0, recoil_1.atom)({
    key: 'me',
    default: {
        accessToken: '',
        adminMember: {
            adminMemberId: 0,
            phoneNumber: '',
            username: '',
            position: undefined,
        },
    },
});
exports.$isAuthorized = (0, recoil_2.selectorWithRefresher)({
    key: 'isAuthorized',
    get: function (_a) {
        var get = _a.get;
        var accessToken = get(exports.$me).accessToken;
        return !!accessToken;
    },
});
exports.$profile = (0, recoil_2.selectorWithRefresher)({
    key: 'profile',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var position = ((_b = get(exports.$me).adminMember) !== null && _b !== void 0 ? _b : {}).position;
        var formattedPosition = position
            ? position.split('_')
            : ['', ''];
        return __spreadArray(__spreadArray([], __read(formattedPosition), false), [position], false);
    },
});
var MASTER_SCORE_TYPES = [ActivityScore_1.ScoreType.MASHUP_LEADER, ActivityScore_1.ScoreType.MASHUP_SUBLEADER];
exports.$isMaster = (0, recoil_2.selectorWithRefresher)({
    key: 'isMaster',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var adminMember = get(exports.$me).adminMember;
        return MASTER_SCORE_TYPES.includes((_b = adminMember === null || adminMember === void 0 ? void 0 : adminMember.position) !== null && _b !== void 0 ? _b : '');
    },
});
