"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var recoil_1 = require("recoil");
var noWaitRegex = /__noWait/;
var selectorFamilyRegex = /__selectorFamily/;
var isAtom = function (snapshot, target) {
    var type = snapshot.getInfo_UNSTABLE(target).type;
    return type === 'atom';
};
var isNoWaitValue = function (_a) {
    var key = _a.key;
    return noWaitRegex.test(key);
};
var isSelectorFamily = function (_a) {
    var key = _a.key;
    return selectorFamilyRegex.test(key);
};
var useRefreshSelectorFamilyByKey = function () {
    return (0, recoil_1.useRecoilCallback)(function (_a) {
        var snapshot = _a.snapshot, refresh = _a.refresh;
        return function (selectorKey) {
            var nodes = Array.from(snapshot.getNodes_UNSTABLE());
            nodes.forEach(function (node) {
                if (isAtom(snapshot, node) || isNoWaitValue(node) || !isSelectorFamily(node)) {
                    return;
                }
                var nodeKey = node.key.split(selectorFamilyRegex)[0];
                if (selectorKey === nodeKey) {
                    refresh(node);
                }
            });
        };
    });
};
exports.default = useRefreshSelectorFamilyByKey;
